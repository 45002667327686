import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";

import Button from "../components/Button";

import DadosSolicitante from "./DadosSolicitante";
import DadosProprietario from "./DadosProprietario";
import EnderecoProprietario from "./EnderecoProprietario";

class PreencherInformacoesForm extends Component {

  render() {
    const { handleSubmit, pristine, invalid, ufAddress, cityAddress, pedido, template } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <DadosSolicitante
          change={ this.props.change }
          solicitanteData={ this.props.solicitanteData }
          proprietarioData={ this.props.proprietarioData }
        />
        { pedido.tipoNota !== "SemNota" ?
          <React.Fragment>
            <DadosProprietario
              change={ this.props.change }
              solicitanteData={ this.props.solicitanteData }
            />
            <EnderecoProprietario
              change={ this.props.change }
            />
          </React.Fragment>
        : '' }
        <div className="action-bar">
          <Button
            type="-yellow"
            text="Avançar"
            disabled={ pedido.tipoNota !== "SemNota" ? (pristine || invalid || ufAddress.id === null || cityAddress.id === null) : (pristine || invalid) }
            template={ template } />
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('PreencherInformacoesForm');

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido,
  ufAddress: store.ufAddressState.ufAddress,
  cityAddress: store.cityAddressState.cityAddress,
  template: store.templateState.template,
  solicitanteData: {
    type: selector(store, 'inputSolicitanteType'),
    name: selector(store, 'inputSolicitanteName'),
    cpf: selector(store, 'inputSolicitanteCPF'),
    cnpj: selector(store, 'inputSolicitanteCNPJ'),
    email: selector(store, 'inputSolicitanteEmail'),
    phone: selector(store, 'inputSolicitantePhone'),
    registration: selector(store, 'inputSolicitanteRegistration')
  },
  proprietarioData: {
    cpf: selector(store, 'inputProprietarioCPF')
  }
});

PreencherInformacoesForm = connect(mapStateToProps)(PreencherInformacoesForm);

export default reduxForm({ form: 'PreencherInformacoesForm' })(PreencherInformacoesForm);
