import React, { Component } from "react";
import { connect } from "react-redux";

import Plate from "./Plate";
import { updateTemplate } from "../actions/template";

import { formatRealValue, formatCPF, formatCNPJ, formatCEP, formatPhone, getDayOfTheWeek } from "../helpers";

import ButtonEditAgendamento from "../components/ButtonEditAgendamento";

class OrderSummary extends Component {

  // Helpers methods
  addZero = i => {
    if (i < 10) {
      i = "0" + i;
    }

    return i;
  }

  render() {
    const { pedido, estampador, agendamento, template } = this.props;

    const day = new Date(agendamento.data).getDate();
    const month = new Date(agendamento.data).getMonth() + 1;
    const dateText = `${this.addZero(day)}/${month}`;
    const dayOfTheWeek = getDayOfTheWeek(new Date(agendamento.data).getDay());
    const rawDate = new Date(agendamento.data);
    const fullDate = new Date(rawDate.setHours(rawDate.getHours()+3));
    const periodText = this.addZero(fullDate.getHours()) + ':' + this.addZero(fullDate.getMinutes());
    const classNamesItemSolicitante = "item" + (pedido.proprietario ? " -divisor " : "");
    const placa = pedido.itens.filter(item => item.opcional === false)[0];
    const descricao = placa.descricao.toLowerCase();

    let type = "carro";

    if (descricao.includes(' moto ')) {
      type = "moto";
    } else if (descricao.includes(' par ')) {
      type = "par";
    }

    let documentoProprietario = "";
    if (pedido.proprietario) {
      documentoProprietario = pedido.proprietario.tipoPessoa === "Fisica" ? formatCPF(pedido.proprietario.documento) : formatCNPJ(pedido.proprietario.documento);
    }
    const complementoProprietario = pedido.proprietario.endereco.complemento ? `${pedido.proprietario.endereco.complemento}, ` : '';
    const documentoSolicitante = pedido.solicitante.tipoPessoa === "Fisica" ? formatCPF(pedido.solicitante.documento) : formatCNPJ(pedido.solicitante.documento);

    const borderStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    const backgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria, color: template.corTexto } : {};

    return (
      <div className="orderSummary">
        <div className="column">
          <div className="columnTitle">Resumo do Pedido</div>
          <div className="item">
            <div className="description">Autorização: { pedido.codigoAutorizacao }</div>
          </div>
          <div className="item -plates">
            <Plate type={ type } placa={ pedido.placa } />
          </div>
          { pedido.itens.map((item) =>
            <div className="item" key={ item.id }>
              <div className="description">{ item.descricao }</div>
            </div>
          )}
          { estampador.uf !== 'BA' && estampador.uf !== 'ES' && estampador.uf !== 'PA' && estampador.uf !== 'CE' && estampador.uf !== 'MT'  && estampador.uf !== 'RN'?
          <div className="item">
            <div className="description">Taxas de Conveniência</div>
            <div className="value">R$ { formatRealValue(pedido.valorTarifa) }</div>
          </div>
          : ''}
          <div className="item -divisor">
            <div className="description">Valor total</div>
            <div className="value">R$ { formatRealValue(pedido.valorTotal) }</div>
          </div>
        </div>
        <div className="column -reduced">
          { pedido.proprietario ?
            <React.Fragment>
              <div className="columnTitle">Proprietário(a)</div>
              <div className="item">
                <div className="description">Pessoa { pedido.proprietario.tipoPessoa }</div>
              </div>
              <div className="item">
                <div className="description">{ pedido.proprietario.nome }</div>
              </div>
              <div className="item">
                <div className="description">{ documentoProprietario }</div>
              </div>
              <div className="item">
                <div className="description">{ pedido.proprietario.email }</div>
              </div>
              <div className="item">
                <div className="description">{ formatPhone(pedido.proprietario.telefone) }</div>
              </div>
              <div className="item">
                <div className="description">
                  { formatCEP(pedido.proprietario.endereco.cep) }, { pedido.proprietario.endereco.logradouro }, { pedido.proprietario.endereco.numero }, { complementoProprietario }{ pedido.proprietario.endereco.bairro }, { pedido.proprietario.endereco.cidade }, { pedido.proprietario.endereco.uf }
                </div>
              </div>
            </React.Fragment>
           : '' }
          <div className={ classNamesItemSolicitante }>
            <div className="columnTitle">Solicitante</div>
          </div>
          <div className="item">
            <div className="description">Pessoa { pedido.solicitante.tipoPessoa }</div>
          </div>
          <div className="item">
            <div className="description">{ pedido.solicitante.nome }</div>
          </div>
          <div className="item">
            <div className="description">{ documentoSolicitante }</div>
          </div>
          <div className="item">
            <div className="description">{ pedido.solicitante.email }</div>
          </div>
          <div className="item">
            <div className="description">{ formatPhone(pedido.solicitante.telefone) }</div>
          </div>
        </div>
        <div className="column">
          <div className="columnTitle">Endereço de Instalação</div>
            <React.Fragment>
              <div className="item">
                <div className="description">
                  { agendamento.enderecoCompleto }
                </div>
              </div>
            </React.Fragment>
          <div className="item -divisor">
            <div className="columnTitle">Período de Instalação</div>
          </div>
          <div className="item -period" style={ borderStyle }>
            <div className="date">
              <div>{ dateText }</div>
              <div className="name">{ dayOfTheWeek }</div>
            </div>
            <div className="time" style={ backgroundStyle }>{ periodText }</div>
            <div className="edit" style={ backgroundStyle }>
            <ButtonEditAgendamento />
            </div>
          </div>
          <div className="item -description">
            <div className="description">A loja entrará em contato com o solicitante para confirmar a instalação.</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido,
  estampador: store.estampadorState.estampador,
  agendamento: store.agendamentoState.agendamento,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateTemplate: updateTemplate
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderSummary);
