import React, { Component } from "react";
import { connect } from "react-redux";

import { updateTourModal } from "../actions/tourModal";

import Button from "./Button";

import '../assets/styles/tour-modal.scss';

class TourModal extends Component {
  state = {
    step: 1
  }

  // Update state methods
  setStep = step => {
    this.setState({
      step: step
    });
  }

  // Helpers methods
  close = _ => {
    this.setStep(1);
    this.props.updateTourModal(false);
  }

  render() {
    return (
      <div className="tour-modal">
        <div className="container">
          <div className="close" onClick={ _ => this.close() }>Fechar</div>
          { this.state.step === 1 ?
            <div className="step step-1">
              <div className="hero"></div>
              <div className="content">
                <div className="number">1<span className="highlight">.</span></div>
                <div className="title">Encontre o emplacador mais perto de você</div>
                <div className="text">Informe a sua localização por estado e cidade, ou busque usando a localização atual automatica permitindo o acesso por GPS.</div>
                <ul className="bullets">
                  <li className="bullet -selected"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                </ul>
                <div className="navigate -next" onClick={ _ => this.setStep(2) }></div>
              </div>
            </div>
          : ''}
          { this.state.step === 2 ?
            <div className="step step-2">
              <div className="hero">
                <div className="navigate -prev" onClick={ _ => this.setStep(1) }></div>
              </div>
              <div className="content">
                <div className="number">2<span className="highlight">.</span></div>
                <div className="title">Informe o número solicitado para validar a autorização</div>
                <div className="text">Apenas placas com autorização podem ser fabricadas.  Se você ainda não possui essa autorização, procure o órgão responsável da sua localidade.</div>
                <ul className="bullets">
                  <li className="bullet"></li>
                  <li className="bullet -selected"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                </ul>
                <div className="navigate -next" onClick={ _ => this.setStep(3) }></div>
              </div>
            </div>
          : ''}
          { this.state.step === 3 ?
            <div className="step step-3">
              <div className="hero">
                <div className="navigate -prev" onClick={ _ => this.setStep(2) }></div>
              </div>
              <div className="content">
                <div className="number">3<span className="highlight">.</span></div>
                <div className="title">Confirme o item da sua autorização</div>
                <div className="text">A autorização especifica o item que você pode encomendar, não podendo adicionar mais placas ao pedido.</div>
                <ul className="bullets">
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet -selected"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                </ul>
                <div className="navigate -next" onClick={ _ => this.setStep(4) }></div>
              </div>
            </div>
          : ''}
          { this.state.step === 4 ?
            <div className="step step-4">
              <div className="hero">
                <div className="navigate -prev" onClick={ _ => this.setStep(3) }></div>
              </div>
              <div className="content">
                <div className="number">4<span className="highlight">.</span></div>
                <div className="title">Pague com cartão ou boleto</div>
                <div className="text">Após informar os dados pessoais, você escolhe se paga com cartão de crédito ou boleto.</div>
                <ul className="bullets">
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet -selected"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                </ul>
                <div className="navigate -next" onClick={ _ => this.setStep(5) }></div>
              </div>
            </div>
          : ''}
          { this.state.step === 5 ?
            <div className="step step-5">
              <div className="hero">
                <div className="navigate -prev" onClick={ _ => this.setStep(4) }></div>
              </div>
              <div className="content">
                <div className="number">5<span className="highlight">.</span></div>
                <div className="title">Agende a instalação na sua casa</div>
                <div className="text">Assim que for confirmado o pagamento, você pode agenda na instalação no endereço que preferir. Se desejar, selecione o endereço do emplacador e leve seu carro no data e horário combinado.</div>
                <ul className="bullets">
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet -selected"></li>
                  <li className="bullet"></li>
                </ul>
                <div className="navigate -next" onClick={ _ => this.setStep(6) }></div>
              </div>
            </div>
          : ''}
          { this.state.step === 6 ?
            <div className="step step-6">
              <div className="hero">
                <div className="navigate -prev" onClick={ _ => this.setStep(5) }></div>
              </div>
              <div className="content">
                <div className="number">6<span className="highlight">.</span></div>
                <div className="title">Pronto!</div>
                <div className="text">O emplacador entrará em contato com o solicitante para confirmar a instalação. E então a nova placa será instalada com sucesso!</div>
                <ul className="bullets">
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet"></li>
                  <li className="bullet -selected"></li>
                </ul>
                <Button
                  type="-yellow"
                  text="Iniciar solicitação"
                  onClick={ _ => this.close() } />
              </div>
            </div>
          : ''}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateTourModal: updateTourModal
}

export default connect(null, mapDispatchToProps)(TourModal);
