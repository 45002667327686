import { ERROR_MODAL_UPDATE, ERROR_MODAL_MESSAGE } from './actionTypes';

export const setMessageErrorModal = value => ({
  type: ERROR_MODAL_MESSAGE,
  errorMessage:value
});

export const updateErrorModal = value => ({
  type: ERROR_MODAL_UPDATE,
  errorModal: value
});
