import { TEMPLATE_UPDATE, TEMPLATE_RESET } from './actionTypes';

export const updateTemplate = value => ({
  type: TEMPLATE_UPDATE,
  template: value
});

export const resetTemplate = _ => ({
  type: TEMPLATE_RESET
});
