import React, { Component } from "react";
import { connect } from "react-redux";

import TextField from "./TextField";
import SubmitButton from "./SubmitButton";

import '../assets/styles/consult-bar.scss';

class ConsultBar extends Component {
  state = {
    plate: '',
    invalidPlateSize: true
  }

  // Update state methods
  setPlaca = e => {
    this.setState({
      plate: e.target.value,
      invalidPlateSize: e.target.value.length !== 7
    });
  }

  render() {
    const { template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className="consultBar">
        <div className="content">
          <h3 className="title">
            Consultar meu pedido
            <div className="detail" style={ detailStyle }></div>
          </h3>
          <p className="text">
            Se você possuí um pedido em andamento, ou um cadastro iniciado nas últimas 24h, informe a sua placa para dar continuidade
          </p>
        </div>
        <div className="form">
          <TextField
            name="Minha placa"
            text="Digite aqui"
            maxLength="7"
            onChange={ e => this.setPlaca(e) } />
          <SubmitButton
            text="Consultar"
            disabled={ this.state.invalidPlateSize }
            onClick={ _ => this.props.onFetchPedido(this.state.plate) } />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(ConsultBar);
