import React, { Component } from "react";
import { connect } from "react-redux";

class CheckIcon extends Component {

  render() {
    const { template } = this.props;
    const fillColor = template.corTexto ? template.corTexto : '#000000';

    return (
      <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1">
          <g id="Desk" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Componentes" transform="translate(-1042.000000, -3413.000000)" fill={ fillColor } fillRule="nonzero">
                  <g id="Group-10" transform="translate(80.000000, 3259.000000)">
                      <path d="M978.863123,155.324072 L978.341305,154.794799 C977.452897,153.953249 976.06153,153.953249 975.173123,154.794799 L969.373487,160.594436 L967.271305,158.484799 C966.851684,158.063719 966.28168,157.827036 965.687214,157.827036 C965.092748,157.827036 964.522744,158.063719 964.103123,158.484799 L963.581305,159.014072 C962.709262,159.887201 962.709262,161.301671 963.581305,162.174799 L967.793123,166.394072 C968.212744,166.815152 968.782748,167.051836 969.377214,167.051836 C969.97168,167.051836 970.541684,166.815152 970.961305,166.394072 L978.863123,158.484799 C979.735166,157.611671 979.735166,156.197201 978.863123,155.324072 Z" id="Path"></path>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(CheckIcon);
