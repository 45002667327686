import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from '../assets/img/logo-horizontal.svg';

class FooterLogo extends Component {

  render() {
    return (
      <Link to={`/`}>
        <img src={ logo } alt="Emplacou" className="logo" />
      </Link>
    );
  }
}

export default FooterLogo;
