import { CITIES_ADDRESS_UPDATE } from '../actions/actionTypes';

const initialState = {
  citiesAddress: []
};

export const citiesAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case CITIES_ADDRESS_UPDATE:
      return {
        ...state,
        citiesAddress: action.citiesAddress
      };
    default:
      return state;
  }
};
