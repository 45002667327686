import { TEMPLATE_UPDATE, TEMPLATE_RESET } from '../actions/actionTypes';

const initialState = {
  template: {
    corPrimaria: '',
    corSecundaria: '',
    corTexto: ''
  }
};

export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_UPDATE:
      return {
        ...state,
        template: action.template
      };
    case TEMPLATE_RESET:
      return initialState;
    default:
      return state;
  }
};
