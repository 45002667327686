import React, { Component } from "react";

import loader from '../assets/img/loader.svg';

import '../assets/styles/loader.scss';

class Loader extends Component {

  render() {
    return (
      <div className="loader">
        <div className="logo">
          <img src={ loader } alt="Emplacou" className="logo" />
        </div>
        <div className="text">Carregando. Aguarde, por favor.</div>
      </div>
    );
  }
}

export default Loader;
