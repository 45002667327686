import React, { Component } from "react";
import { connect } from "react-redux";

class AlertIcon extends Component {

  render() {
    const { template } = this.props;
    const fillColor = template.corPrimaria ? template.corPrimaria : '#FFCC00';

    return (
      <svg width="34px" height="32px" viewBox="0 0 34 32" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icone/tour-Copy" transform="translate(-1.000000, -2.000000)" fillRule="nonzero">
            <g id="Group" transform="translate(0.000000, 2.000000)">
              <g id="fixo" transform="translate(0.831531, 0.496291)">
                <path d="M8.09858816,26.3316471 L26.746269,26.3316471 C30.6639128,26.3316471 33.1161397,22.0924584 31.1549169,18.6955898 L21.8311638,2.54614572 C19.8724729,-0.846532261 14.9750907,-0.850897519 13.0136933,2.54614572 L3.68994019,18.6955898 C1.7308127,22.0883551 4.17649177,26.3316471 8.09858816,26.3316471 Z" id="Path" fill="#323232"></path>
                <path d="M6.6385842,20.3980401 L15.9624246,4.24859608 C16.6111018,3.12506615 18.2328822,3.12366927 18.8823452,4.24859608 L28.2061856,20.3980401 C28.8548629,21.52157 28.0451949,22.9268337 26.7461817,22.9268337 L8.09858816,22.9268337 C6.80132099,22.9268337 5.98912121,21.5230542 6.6385842,20.3980401 Z" id="Path" fill="#FFFFFF"></path>
                <path d="M17.2041657,17.4618807 C18.2872733,17.4618807 19.1685315,16.5806226 19.1685315,15.497515 L19.1685315,8.94954168 C19.1685315,7.86634673 18.2872733,6.98517589 17.2041657,6.98517589 C16.1209707,6.98517589 15.2397999,7.86634673 15.2397999,8.94954168 L15.2397999,15.497515 C15.2397999,16.5806226 16.1209707,17.4618807 17.2041657,17.4618807 Z" id="Path" fill="#323232"></path>
                <path d="M17.2041657,21.8271381 C18.2872733,21.8271381 19.1685315,20.9458799 19.1685315,19.8627723 C19.1685315,18.7795773 18.2872733,17.8984065 17.2041657,17.8984065 C16.1209707,17.8984065 15.2397999,18.7795773 15.2397999,19.8627723 C15.2397999,20.9458799 16.1209707,21.8271381 17.2041657,21.8271381 Z" id="Path" fill="#323232"></path>
                <path d="M28.1240248,28.9552964 C28.1240248,28.6795348 27.90058,28.4560901 27.6248184,28.4560901 L0.667675551,28.4560901 C0.392013804,28.4560901 0.168469201,28.6795348 0.168469201,28.9552964 C0.168469201,29.2309582 0.392013804,29.4545028 0.667675551,29.4545028 L27.6248184,29.4545028 C27.90058,29.4545028 28.1240248,29.2309582 28.1240248,28.9552964 Z" id="Path" fill="#FFFFFF"></path>
                <circle id="Oval" fill="#FFFFFF" cx="27.6676756" cy="31.0029155" r="1"></circle>
              </g>
              <g id="cor_primaria" transform="translate(4.500000, 1.000000)" fill={ fillColor }>
                <path d="M21.9761905,30 L7.49920635,30 C7.22344476,30 7,30.2234448 7,30.4992063 C7,30.7748681 7.22344476,30.9984127 7.49920635,30.9984127 L21.9761905,30.9984127 C22.2519521,30.9984127 22.4753968,30.7748681 22.4753968,30.4992063 C22.4753968,30.2234448 22.2519521,30 21.9761905,30 Z" id="Path"></path>
                <path d="M29.4936508,30 L25.9992063,30 C25.7234448,30 25.5,30.2234448 25.5,30.4992063 C25.5,30.7748681 25.7234448,30.9984127 25.9992063,30.9984127 L29.4936508,30.9984127 C29.7694124,30.9984127 29.9928571,30.7748681 29.9928571,30.4992063 C29.9928571,30.2234448 29.7694124,30 29.4936508,30 Z" id="Path"></path>
                <path d="M17.4065448,2.47896233 L26.7302979,18.6284064 C28.3532132,21.4393702 26.3273846,24.9548865 23.0777125,24.9548865 L4.43011896,24.9548865 C1.18411362,24.9548865 -0.847389832,21.4424258 0.77753355,18.6284064 L10.1012866,2.47896233 C11.724202,-0.331914158 15.7816214,-0.335493669 17.4065448,2.47896233 Z M11.5378928,3.30836122 L2.21405243,19.4578053 C1.22951229,21.1631367 2.45841953,23.296176 4.43011896,23.296176 L23.0777998,23.296176 C25.0470546,23.296176 26.279716,21.1654066 25.2938663,19.4578053 L15.9700259,3.30836122 C14.9854857,1.6030298 12.5237425,1.60084717 11.5378928,3.30836122 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(AlertIcon);
