import React, { Component } from "react";
import { connect } from "react-redux";

import { updateUF } from "../actions/uf";
import { updateUFs } from "../actions/ufs";
import { updateCities } from "../actions/cities";
import { updateGroup } from "../actions/group";
import { updateEstampadores } from "../actions/estampadores";
import { updateTemplate } from "../actions/template";
import { updateEstampador } from "../actions/estampador";
import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import SearchBar from "../components/SearchBar";
import SearchResults from "../components/SearchResults";
import ConsultBar from "../components/ConsultBar";
import SelectCidade from "../components/SelectCidade";
import ButtonSearch from "../components/ButtonSearch";
import ButtonLocation from "../components/ButtonLocation";

import { redirectToStep } from "../helpers";

const UFS_URL = `localidades/ufs`;
const CITIES_URL = `localidades/cidades/uf/{uf}/?hasEstampador=true`;
const GROUP_URL = `grupos/url/`;
const SEARCH_BY_CITY_URL = `estampadores/cidade/`;
const SEARCH_BY_GROUP = `estampadores/uf/{uf}/grupo/{grupo}`;
const PEDIDO_BY_GROUP_URL = `pedidos/placa​/{placa}/?idGrupo={idGrupo}`;
const ESTAMPADOR_URL = `estampadores/`;

class Grupo extends Component {
  // Lifecycle methods
  async componentDidMount() {
    if (this.props.ufs.length !== 0) {
      this.loadCommonData();
    } else {
      await this.fetchUFs();
      this.loadCommonData();
    }
  }

  loadCommonData = async () => {
    await this.setUF(this.props.match.params.uf);
    await this.fetchGroup(this.props.match.params.grupo);

    this.prepareTemplate(this.props.group);
    this.fetchCities();
    this.fetchEstampadoresByGroup();
  }

  // Fetch data methods
  fetchUFs = async () => {
    this.props.updateLoader(true);

    try {
      const ufs = await api.get(UFS_URL).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateUFs(ufs.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchCities = async () => {
    let url = CITIES_URL;
    url = url.replace('{uf}', this.props.uf.id);

    this.props.updateLoader(true);

    try {
      const cities = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateCities(cities.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchGroup = async groupName => {
    this.props.updateLoader(true);

    try {
      const group = await api.get(`${GROUP_URL}${groupName}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateGroup(group.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchEstampadoresByCity = async () => {
    this.props.updateLoader(true);

    try {
      const estampadores = await api.get(`${SEARCH_BY_CITY_URL}${this.props.city.id}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampadores(estampadores.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchEstampadoresByGroup = async () => {
    let url = SEARCH_BY_GROUP;
    url = url.replace('{uf}', this.props.uf.sigla).replace('{grupo}', this.props.match.params.grupo);

    this.props.updateLoader(true);

    try {
      const estampadores = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampadores(estampadores.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchPedidoByGroup = async placa => {
    let url = PEDIDO_BY_GROUP_URL;
    url = url.replace(`{placa}`, placa).replace(`{idGrupo}`, this.props.group.id);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.handleValidPlate(pedido.data);
    } catch (err) {
      this.handleInvalidPlate(err);
    }

    this.props.updateLoader(false);
  }

  fetchEstampador = async url => {
    this.props.updateLoader(true);

    try {
      const estampador = await api.get(`${ESTAMPADOR_URL}${url}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampador(estampador.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  setUF = receivedUF => {
    // 1. Retrieve UF from URL
    let uf = this.props.ufs.filter(uf => uf.sigla.toLowerCase() === receivedUF)[0];

    // 2. Convert UF sigla to lowercase
    uf.sigla = uf.sigla.toLowerCase();

    // 3. Set new UF state
    this.props.updateUF(uf);
  }

  handleValidPlate = async pedido => {
    this.props.updatePedido(pedido);

    await this.fetchEstampador(pedido.estampador.url);

    this.prepareTemplate(pedido.estampador);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidPlate = err => {
    if (err.response.status === 404 || err.response.status === 409) {
      this.props.history.push("/pedido-nao-encontrado");
    }
    if (err.response.status === 500) {
      this.props.updateErrorModal(true);
    }
  }

  prepareTemplate = source => {
    const template = {
      corPrimaria: source.corPrimaria,
      corSecundaria: source.corSecundaria,
      corTexto: source.corTexto
    }

    this.props.updateTemplate(template);
  }

  render() {
    return (
      <Base>
        <SearchBar>
          <SelectCidade />
          <ButtonSearch />
          <div className="separator">
            <ButtonLocation />
          </div>
        </SearchBar>
        <SearchResults />
        <ConsultBar onFetchPedido={ e => this.fetchPedidoByGroup(e) } />
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  uf: store.ufState.uf,
  ufs: store.ufsState.ufs,
  city: store.cityState.city,
  group: store.groupState.group
});

const mapDispatchToProps = {
  updateUF: updateUF,
  updateUFs: updateUFs,
  updateCities: updateCities,
  updateGroup: updateGroup,
  updateEstampadores: updateEstampadores,
  updateEstampador: updateEstampador,
  updateTemplate: updateTemplate,
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Grupo);
