// Access Token
const ACCESS_TOKEN_KEY = "accessToken";
export const getAccessToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);
export const setAccessToken = token => sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
export const removeAccessToken = () => sessionStorage.removeItem(ACCESS_TOKEN_KEY);

// Refresh Token
const REFRESH_TOKEN_KEY = "refreshToken";
export const getRefreshToken = () => sessionStorage.getItem(REFRESH_TOKEN_KEY);
export const setRefreshToken = token => sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
export const removeRefreshToken = () => sessionStorage.removeItem(REFRESH_TOKEN_KEY);
