import { PEDIDO_UPDATE } from '../actions/actionTypes';

const initialState = {
  pedido: {
    id: null,
    estampador: {
      id: null,
      nome: "",
      cnpj: "",
      url: "",
      logo: "",
      corPrimaria: "",
      corSecundaria: "",
      corTexto: "",
      email: "",
      telefone: "",
      tipoNota: "SemNota"
    },
    solicitante: {
      id: null,
      tipoPessoa: "Fisica",
      documento: "",
      email: "",
      telefone: "",
      codigoSistemaPagamento: "",
      nome: "",
      matricula: "",
      endereco: {
        id: null,
        cep: "",
        logradouro: "",
        numero: null,
        complemento: "",
        bairro: "",
        uf: "",
        cidade: "",
        ibgeCidade: null
      }
    },
    proprietario: {
      id: null,
      tipoPessoa: "Fisica",
      documento: "",
      email: "",
      telefone: "",
      codigoSistemaPagamento: "",
      nome: "",
      endereco: {
        id: null,
        cep: "",
        logradouro: "",
        numero: null,
        complemento: "",
        bairro: "",
        uf: "",
        cidade: "",
        ibgeCidade: null
      }
    },
    status: "ConsultaAutorizacao",
    dataCriacao: "",
    tipoNota: "SemNota",
    tipoPagamento: "Boleto",
    valorTotal: null,
    valorTarifa: null,
    idCobranca: "",
    sistemaPagamento: "Asaas",
    codigoAutorizacao: "",
    placa: "",
    chassi: "",
    itens: [
      {
        id: null,
        opcional: false,
        descricao: "",
        codigoProdutoSistemaRastreabilidade: "",
        valor: null
      }
    ],
    urlCobranca: "",
    documentoSolicitante: null,
    formaEntrega: null
  }
};

export const pedidoReducer = (state = initialState, action) => {
  switch (action.type) {
    case PEDIDO_UPDATE:
      return {
        ...state,
        pedido: action.pedido
      };
    default:
      return state;
  }
};
