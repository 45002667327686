import { GROUP_UPDATE } from '../actions/actionTypes';

const initialState = {
  group: {
    id: null,
    nome: '',
    cnpj: '',
    url: '',
    logo: '',
    corPrimaria: '',
    corSecundaria: '',
    corTexto: '',
    email: '',
    telefone: '',
    endereco: '',
    tipoNota: ''
  }
};

export const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_UPDATE:
      return {
        ...state,
        group: action.group
      };
    default:
      return state;
  }
};
