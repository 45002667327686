import React, { Component } from "react";
import { Link } from "react-router-dom";

import Base from "../components/Base";
import Button from "../components/Button";

import '../assets/styles/intern.scss';

class PaginaNaoEncontrada extends Component {

  render() {
    return (
      <Base hideTitle={ true }>
        <div className="internContainer">
          <h3 className="title">
            Página não encontrada
            <div className="detail"></div>
          </h3>
          <p className="text">
            A página que você procurou não pode ser encontrada.
          </p>
          <div className="action-bar">
            <Link to={`/`} style={{ textDecoration: 'none' }}>
              <Button
                type="-yellow"
                text="Voltar para a página incial" />
            </Link>
          </div>
        </div>
      </Base>
    );
  }
}

export default PaginaNaoEncontrada;
