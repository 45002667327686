import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import RenderField from "../components/RenderField";
import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Button from "../components/Button";

import { formatRealValue, creditCardMask, cardExpirationMask, cardCCVMask, cpfMask, validateCPF } from "../helpers";

import '../assets/styles/text-field.scss';
import '../assets/styles/radio-field.scss';

import visa from '../assets/img/cards/visa.png';
import mastercard from '../assets/img/cards/mastercard.png';
import discover from '../assets/img/cards/discover.png';
import hipercard from '../assets/img/cards/hipercard.png';
import elo from '../assets/img/cards/elo.png';
import american from '../assets/img/cards/american.png';

const TAXA_URL = `taxas/estampador/{idEstampador}/tipopagamento/{tipoPagamento}`;

class FormaDePagamentoForm extends Component {
  state = {
    checked: "1"
  }

  // Lifecycle methods
  async componentDidMount() {
    let newPedido = this.props.pedido;
    newPedido.tipoPagamento = newPedido.tipoPagamento === 0 ? "CartaoCredito" : newPedido.tipoPagamento;

    let tipoPagamento;
    if (newPedido.tipoPagamento === "CartaoCredito"){
      tipoPagamento = "1";
    } else if (newPedido.tipoPagamento === "CartaoCredito") {
      tipoPagamento = "2";
    } else {
      tipoPagamento = "3";
    }

    this.setState({
      checked: tipoPagamento
    });

    const tarifa = await this.fetchTarifa(newPedido.tipoPagamento);
    newPedido.valorTarifa = this.getTarifaResult(tarifa, newPedido.valorTotal);

    this.props.updatePedido(newPedido);

    this.forceUpdate();
  }

  // Update state methods
  updateChecked = e => {
    this.setState({
      checked: e.target.value
    });

    let tipoPagamento;
    if (e.target.value === "1"){
      tipoPagamento = "CartaoCredito";
    } else if (e.target.value === "2") {
      tipoPagamento = "Boleto";
    } else {
      tipoPagamento = "PIX";
    }

    this.updateTarifa(tipoPagamento);
  }

  // Fetch data methods
  fetchTarifa = async tipoPagamento => {
    let url = TAXA_URL;
    url = url.replace(`{idEstampador}`, this.props.pedido.estampador.id).replace(`{tipoPagamento}`, tipoPagamento);

    this.props.updateLoader(true);

    try {
      const tarifa = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      return tarifa.data;
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);

    return undefined;
  }

  updateTarifa = async tipoPagamento => {
    let newPedido = this.props.pedido;

    const tarifa = await this.fetchTarifa(tipoPagamento);
    newPedido.valorTarifa = this.getTarifaResult(tarifa, newPedido.valorTotal);
    newPedido.tipoPagamento = tipoPagamento;

    this.props.updatePedido(newPedido);

    this.forceUpdate();
  }

  getTarifaResult = (tarifa, valorTotal) => {
    if (tarifa === undefined) return 0;

    let valorTarifaResult = tarifa.tarifaTaxa; // boleto

    if (tarifa.tipoPagamento === "CartaoCredito") {
      let tarifaTmp = tarifa.tarifaPercentual > 1 ? (tarifa.tarifaPercentual * valorTotal)/100 : tarifa.tarifaPercentual * valorTotal;
      valorTarifaResult = tarifaTmp + tarifa.tarifaTaxa;
    }

    return valorTarifaResult;
  }

  render() {
    const { handleSubmit, pristine, invalid, pedido, estampador, template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    const optionBeforeStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    const optionAfterStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <form onSubmit={handleSubmit}>
        <h3 className="title">
          Preencha os dados de pagamento
          <div className="detail" style={ detailStyle }></div>
        </h3>
        
        { pedido.formaEntrega === 'Entrega' ?
        <p className="text">
            <span className="bold">Importante: </span>A cobertura máxima de entrega deste estampador é de: <span className="bold">{ pedido.estampador.entregaKilometragemMaxima } Km </span>
            ao redor do endereço da loja: <span className="bold"> {pedido.estampador.endereco }. </span> Caso você deseje receber o seu pedido fora da cobertura máxima, selecione outro estampador.
        </p>   
        : ''}

        <div className="row">
          <div className="radioField">
            <div className="options">
              <label className={"option" + (this.state.checked === "1" ? " -selected " : "")}>
                <div className="before" style={ optionBeforeStyle }></div>
                <Field name="inputPagamentoTipo" component="input" type="radio" value="1" className="radioInput" onClick={ e => this.updateChecked(e) } />Cartão de Crédito
                <div className="after" style={ optionAfterStyle }></div>
              </label>
              <label className={"option" + (this.state.checked === "2" ? " -selected " : "")}>
                <div className="before" style={ optionBeforeStyle }></div>
                <Field name="inputPagamentoTipo" component="input" type="radio" value="2" className="radioInput" onClick={ e => this.updateChecked(e) } />Boleto
                <div className="after" style={ optionAfterStyle }></div>
              </label>
              <label className={"option" + (this.state.checked === "3" ? " -selected " : "")}>
                <div className="before" style={ optionBeforeStyle }></div>
                <Field name="inputPagamentoTipo" component="input" type="radio" value="3" className="radioInput" onClick={ e => this.updateChecked(e) } />PIX
                <div className="after" style={ optionAfterStyle }></div>
              </label>
            </div>
          </div>
        </div>
        <div className="paymentStepContainer">
          <div className="orderSummary">
            <div className="orderSummary-title">Resumo do Pedido</div>
            { pedido.itens.map(item =>
              <div className="item" key={ item.id }>
                <div className="description">{ item.descricao.toLowerCase() }</div>
              </div>
            )}
            { estampador.uf !== 'BA' && estampador.uf !== 'ES' && estampador.uf !== 'PA' && estampador.uf !== 'CE' && estampador.uf !== 'MT'  && estampador.uf !== 'RN'?
            <div className="item">
              <div className="description">Taxas de<br />Conveniência</div>
              <div className="value">R$ { formatRealValue(pedido.valorTarifa) }</div>
            </div>
            : ''}
            <div className="item -divisor">
              <div className="description">Valor total</div>
              <div className="value">R$ { formatRealValue(pedido.valorTotal) }</div>
            </div>
          </div>
          { this.state.checked === "1" ?
            <div className="fields">
              <div className="cards">
                <p className="text">Bandeiras aceitas:</p>
                <ul className="list">
                  <li className="item"><img src={ visa } alt="Visa" className="image" /></li>
                  <li className="item"><img src={ mastercard } alt="MasterCard" className="image" /></li>
                  <li className="item"><img src={ discover } alt="Discover" className="image" /></li>
                  <li className="item"><img src={ hipercard } alt="Hipercard" className="image" /></li>
                  <li className="item"><img src={ elo } alt="Elo" className="image" /></li>
                  <li className="item"><img src={ american } alt="American Express" className="image" /></li>
                </ul>
              </div>
              <div className="row">
                <div className="textField">
                  <div className="border" style={ detailStyle }></div>
                  <label className="label">Titular do Cartão *</label>
                  <Field name="inputPagamentoCartaoTitular" component="input" type="text" placeholder="Digite aqui" className="field" autoComplete="cc-name" required />
                </div>
                <div className="textField">
                  <div className="border" style={ detailStyle }></div>
                  <label className="label">CPF do Titular *</label>
                  <Field name="inputPagamentoCartaoTitularCPF" component={RenderField} type="text" placeholder="000.000.000-00" minLength="14" maxLength="14"  validate={validateCPF} {...cpfMask} style={detailStyle} required />
                </div>
              </div>
              <div className="row">
                <div className="textField">
                  <div className="border" style={ detailStyle }></div>
                  <label className="label">Número do Cartão *</label>
                  <Field name="inputPagamentoCartaoNumero" component="input" type="text" placeholder="0000 0000 0000 0000" className="field" required {...creditCardMask} />
                </div>
                <div className="textField -reduced">
                  <div className="border" style={ detailStyle }></div>
                  <label className="label">Vencimento *</label>
                  <Field name="inputPagamentoCartaoVencimento" component="input" type="text" placeholder="mm/aaaa" className="field" required {...cardExpirationMask} />
                </div>
                <div className="textField -reduced">
                  <div className="border" style={ detailStyle }></div>
                  <label className="label">CCV *</label>
                  <Field name="inputPagamentoCartaoCCV" component="input" type="text" maxLength="3" placeholder="000" className="field" required {...cardCCVMask} />
                </div>
              </div>
            </div>
          : '' }
          { this.state.checked === "2" ?
            <p className="text">
              O pagamento por boleto pode demorar até <span className="bold">48 horas</span> para ocorrer. É preciso aguardar a confirmação para agendar a instalação.
            </p>
          : '' }
          { this.state.checked === "3" ?
            <p className="text">
              Após o pagamento através do QR Code do PIX, aguarde alguns segundos para a confirmação para então agendar a instalação.
            </p>
          : '' }
        </div>
        <div className="action-bar">
          { this.state.checked === "1" ?
            <Button
              type="-yellow"
              text="Confirmar pagamento"
              disabled={ pristine || invalid }
              template={ template } />
          : '' }
          { this.state.checked === "2" ?
            <Button
              type="-dark"
              text="Gerar boleto" />
          : '' }
          { this.state.checked === "3" ?
            <Button
              type="-dark"
              text="Gerar QR Code PIX" />
          : '' }
        </div>
      </form>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido,
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

FormaDePagamentoForm = connect(mapStateToProps, mapDispatchToProps)(FormaDePagamentoForm);

export default reduxForm({ form: 'FormaDePagamentoForm' })(FormaDePagamentoForm);
