import React, { Component } from "react";
import { connect } from "react-redux";

import { updateErrorModal, setMessageErrorModal} from "../actions/errorModal";

import loader from '../assets/img/loader.svg';

import '../assets/styles/error-modal.scss';


class ErrorModal extends Component {
  render() {
    const { template, estampador } = this.props;
    const errorMessages = [
      {key:"GENERAL",title:"Um erro inesperado aconteceu", message: "Revise seus dados ou tente novamente em alguns instantes."},      
      {key:"ERROR_PAY", title:"Erro ao realizar pagamento", message: "Não foi possível realizar seu pagamento. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"ESTAMPADOR_NOT_FOUND",title:"Estampador não encontrado", message: "Não foi possível localizar o estampador informado. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"PEDIDO_NOT_FOUND", title:"Pedido não encontrado", message: "Não foi possível localizar o pedido informado. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"PEDIDO_NOT_PENDING", title:"Pedido não encontrado", message: "Não foi possível localizar o pedido informado. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"PESSOA_NOT_FOUND", title:"Pessoa não encontrada", message: "Não foi possível localizar a pessoa informado. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"UNAUTHORIZED_AGENDAMENTO", title:"Agendamento não autorizado", message: "Não foi possível autorizar o agendamento informado. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"UNAUTHORIZED_CLIENT", title:"Cliente não autorizado", message: "Não foi possível autorizar o cliente informado. Revise seus dados ou tente novamente em alguns instantes."},
      {key:"UNAUTHORIZED_ESTAMPADOR", title:"Estampador não autorizado", message: "Não foi possível autorizar o cliente informado. Revise seus dados ou tente novamente em alguns instantes."},      
      {key:"EXCEEDED_AGENDAMENTO_DISTANCIA_ENTREGA", title:"Agendamento não autorizado", message: "Endereço de instalação fora da área de entrega do estampador. Encolha um endereço de intalação diferente."},
    ];

    var message = 'Revise seus dados ou tente novamente em alguns instantes.';
    var title = 'Um erro inesperado aconteceu';
    const errorMessage = errorMessages.find(m => m.key === this.props.errorMessage);
    if(errorMessage != null){
      message = errorMessage.message;
      title = errorMessage.title
    } 

    let logo = loader
    const backgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria, color: template.corTexto } : {};
    const textStyle = template.corTexto ? { color: template.corTexto } : {};
    const classColorStyle = template.corTexto === '#000000' ? 'link -whatsapp-black' :'link -whatsapp';
    if (template.corPrimaria) {
       if (estampador.logo) {
        logo = estampador.logo;
      }     
    }

    return (
      <div className="error-modal">
        <div className="container" style={backgroundStyle}>
          <div className="close" onClick={ _ => this.props.updateErrorModal(false) }>Fechar</div>
          <div className="logo">
            <img src={ logo } alt="Emplacou" className="logo" />
          </div>
          <div className="title" style={textStyle}>{title}</div>
          <div className="text" style={textStyle}>{message}</div>
          <div className="support-links">
            <a href="mailto:contato@emplacou.com.br" style={textStyle} className="link">contato@emplacou.com.br</a>
            <div className="link-spacer" style={textStyle}> - </div>
            <a href="https://wa.me/555130376494?text=Olá,%20solicito%20suporte%20para%20o%20Emplacou." style={textStyle} target="_blank" rel="noopener noreferrer" className={classColorStyle}>WhatsApp</a>
        </div> 
        </div>       
      </div>
    );
  }
}

const mapStateToProps = store => ({
  errorMessage: store.errorModalState.errorMessage,
  template: store.templateState.template,
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updateErrorModal: updateErrorModal,
  setMessageErrorModal: setMessageErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
