import React, { Component } from "react";
import { connect } from "react-redux";

import Options from "../components/Options";

import '../assets/styles/select.scss';

class SelectEstadoAddress extends Component {
  state = {
    focused: false,
    ufsAddress: [],
    search: ''
  }

  // Event handler methods
  handleSelectFocus = _ => {
    this.setState(state => ({
      focused: !state.focused,
      ufsAddress: this.props.ufsAddress,
      search: ''
    }));
  }

  handleInputChange = e => {
    const ufsAddress = this.props.ufsAddress.filter(uf => uf.nome.toLowerCase().includes(e.target.value.toLowerCase()));

    this.setState({
      ufsAddress: ufsAddress,
      search: e.target.value
    });
  }

  render() {
    const { ufAddress, ufsAddress, template } = this.props;
    const classNames = "select" + (this.state.focused ? " -focused" : "") + (ufsAddress.length === 0 ? " -disabled" : "");
    const borderStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className={ classNames }>
        <div className="border" style={ borderStyle }></div>
        <div className="label">Estado *</div>
        <div className="field" onClick={ _ => this.handleSelectFocus() }>
          <input
            className={"selected " + (ufAddress.id === null ? "-empty" : "")}
            onChange={ e => this.handleInputChange(e) }
            placeholder={ ufAddress.id === null ? 'Selecione' : ufAddress.nome }
            value={ this.state.search }>
          </input>
          <Options>
            { this.state.ufsAddress.map((uf, key) => (
              <div
                className="option"
                key={ key }
                data-id={ uf.id }
                data-nome={ uf.nome }
                data-sigla={ uf.sigla }
                onClick={ e => this.props.onClick(e) }>
                { uf.nome }
              </div>
            ))}
          </Options>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  ufAddress: store.ufAddressState.ufAddress,
  ufsAddress: store.ufsAddressState.ufsAddress,
  template: store.templateState.template
});

export default connect(mapStateToProps)(SelectEstadoAddress);