import React, { Component } from "react";
import { connect } from "react-redux";

import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import ErrorModal from "./ErrorModal";
import TourModal from "./TourModal";

class Base extends Component {

  render() {
    const { hideTitle, loader, errorModal, tourModal } = this.props;
    const classNames = "main" + (hideTitle ? " -reduced" : "");

    return (
      <React.Fragment>
        <Header hideTitle={ hideTitle } />
        <main className={ classNames }>
          { this.props.children }
        </main>
        <Footer />
        { loader ? <Loader /> : ''}
        { errorModal ? <ErrorModal /> : ''}
        { tourModal ? <TourModal /> : ''}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => ({
  loader: store.loaderState.loader,
  errorModal: store.errorModalState.errorModal,
  tourModal: store.tourModalState.tourModal
});

export default connect(mapStateToProps)(Base);
