import { ESTAMPADORES_UPDATE, ESTAMPADORES_RESET } from '../actions/actionTypes';

const initialState = {
  estampadores: []
};

export const estampadoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case ESTAMPADORES_UPDATE:
      return {
        ...state,
        estampadores: action.estampadores
      };
    case ESTAMPADORES_RESET:
      return initialState;
    default:
      return state;
  }
};
