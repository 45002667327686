import axios from "axios";

import { getAccessToken, setAccessToken, getRefreshToken, setRefreshToken } from "./token";

const AUTH_URL = `authentication/authenticate`;
const REFRESH_URL = `authentication/refresh`;

var baseUrlApi = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: baseUrlApi
});

api.interceptors.request.use(async config => {
  if (_isAuthenticated()) {
    const isRefreshing = config.method === 'post' && config.url.includes(REFRESH_URL);

    if (!isRefreshing) {
      await _refresh();
      _setHeaders(config);
    }
  } else {
    const isAuthenticating = config.method === 'post' && config.url === AUTH_URL;

    if (!isAuthenticating) {
      await _authenticate();
      _setHeaders(config);
    }
  }

  return config;
});

const _setHeaders = config => {
  const token = getAccessToken();

  config.headers.Authorization = `Bearer ${token}`;
};

const _isAuthenticated = () => getAccessToken() !== null;

const _authenticate = async () => {
  const res = await _authenticateWithAPI();

  _setTokens(res.data.accessToken, res.data.refreshToken);
};

const _authenticateWithAPI = async () => await api.post(AUTH_URL, {
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET
});

const _refresh = async () => {
  const res = await _refreshWithAPI();

  _setTokens(res.data.accessToken, res.data.refreshToken);
};

const _refreshWithAPI = async () => await api.post(`${REFRESH_URL}?refreshToken=${getRefreshToken()}`);

const _setTokens = (accessToken, refreshToken) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
}
