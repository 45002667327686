import React, { Component } from "react";
import { connect } from "react-redux";

import { updateEstampadores } from "../actions/estampadores";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Button from "../components/Button";

const SEARCH_BY_UF_URL = `estampadores/uf/`;
const SEARCH_BY_CITY_URL = `estampadores/cidade/`;

class ButtonSearch extends Component {

  // Fetch data methods
  fetchEstampadoresByUF = async () => {
    this.props.updateLoader(true);

    try {
      const estampadores = await api.get(`${SEARCH_BY_UF_URL}${this.props.uf.sigla}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampadores(estampadores.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchEstampadoresByCity = async () => {
    this.props.updateLoader(true);

    try {
      const estampadores = await api.get(`${SEARCH_BY_CITY_URL}${this.props.city.id}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampadores(estampadores.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Event handler methods
  onClickSearch = () => {
    if (this.props.city.id !== null) {
      this.fetchEstampadoresByCity();
    } else {
      //this.fetchEstampadoresByUF();
    }
  }

  render() {
    const { city, template } = this.props;

    return (
      <Button
        type="-search"
        text="Buscar estampador"
        disabled={ city.id === null }
        template={ template }
        onClick={ e => this.onClickSearch(e) } />
    );
  }
}

const mapStateToProps = store => ({
  uf: store.ufState.uf,
  city: store.cityState.city,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateEstampadores: updateEstampadores,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonSearch);
