import React, { Component } from "react";
import { connect } from "react-redux";

class Plate extends Component {
  render() {
    const { type, placa, template } = this.props;

    const placaMoto1 = placa.substring(0, 3);
    const placaMoto2 = placa.substring(3, 7);

    const detailStyle = template.corPrimaria ? { fill: template.corPrimaria } : { fill: '#FFCC00'};

    return (
      <div className="plate">
        { type === "carro" ?
          <svg width="122px" height="38px" viewBox="0 0 122 38">
            <g id="Desk" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Componentes" transform="translate(-436.000000, -3694.000000)">
                <g id="1P" transform="translate(437.000000, 3695.000000)">
                  <g id="fixo" fill="#FAFAFA" stroke="#D2D2D2">
                    <rect id="Rectangle-Copy-15" x="-0.5" y="-0.5" width="121" height="37" rx="6.75"></rect>
                  </g>
                  <path d="M6.75,0 L113.25,0 C116.977922,-6.84808173e-16 120,3.02207794 120,6.75 L120,6.75 L120,6.75 L0,6.75 C-4.56538782e-16,3.02207794 3.02207794,6.84808173e-16 6.75,0 Z" id="Rectangle-Copy-17" style={ detailStyle }></path>
                  <text id="XXX0X00" fontFamily="SquadaOne-Regular, Squada One" fontSize="22.5" fontWeight="normal" letterSpacing="0.75" fill="#959595">
                    <tspan x="21.5" y="29">{ placa }</tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        : "" }
        { type === "moto" ?
          <svg width="73px" height="57px" viewBox="0 0 73 57">
            <g id="Desk" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Componentes" transform="translate(-1063.000000, -3684.000000)">
                <g id="1R" transform="translate(1064.000000, 3685.000000)">
                  <g id="fixo" transform="translate(0.500000, 0.000000)" fill="#FAFAFA" stroke="#D2D2D2">
                    <rect id="Rectangle-Copy-18" x="-0.5" y="-0.5" width="70.75" height="55.75" rx="6.75"></rect>
                  </g>
                  <path d="M7.25,0 L63.5,0 C67.2279221,-6.84808173e-16 70.25,3.02207794 70.25,6.75 L70.25,6.75 L70.25,6.75 L0.5,6.75 C0.5,3.02207794 3.52207794,6.84808173e-16 7.25,0 Z" id="Rectangle-Copy-19" style={ detailStyle }></path>
                  <text id="XXX-0X00" fontFamily="SquadaOne-Regular, Squada One" fontSize="22.5" fontWeight="normal" letterSpacing="0.75" fill="#959595">
                    <tspan x="18.89125" y="31">{ placaMoto1 }</tspan>
                    <tspan x="13.375" y="49.75">{ placaMoto2 }</tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        : "" }
        { type === "par" ?
          <svg width="161px" height="71px" viewBox="0 0 161 71">
            <g id="Desk" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Componentes" transform="translate(-610.000000, -3677.000000)">
                <g id="2P" transform="translate(611.000000, 3678.000000)">
                  <g id="fixo" fill="#FAFAFA" stroke="#D2D2D2">
                    <rect id="Rectangle-Copy-15" x="-0.5" y="-0.5" width="121" height="37" rx="6.75"></rect>
                    <rect id="Rectangle-Copy-16" x="37.75" y="32.5" width="121" height="37" rx="6.75"></rect>
                  </g>
                  <path d="M6.75,0 L113.25,0 C116.977922,-6.84808173e-16 120,3.02207794 120,6.75 L120,6.75 L120,6.75 L0,6.75 C-4.56538782e-16,3.02207794 3.02207794,6.84808173e-16 6.75,0 Z" id="Rectangle-Copy-17" style={ detailStyle }></path>
                  <path d="M45,33 L151.5,33 C155.227922,33 158.25,36.0220779 158.25,39.75 L158.25,39.75 L158.25,39.75 L38.25,39.75 C38.25,36.0220779 41.2720779,33 45,33 Z" id="Rectangle-Copy-20" style={ detailStyle }></path>
                  <text id="XXX0X00" fontFamily="SquadaOne-Regular, Squada One" fontSize="22.5" fontWeight="normal" letterSpacing="0.75" fill="#959595">
                    <tspan x="21.5" y="29">{ placa }</tspan>
                  </text>
                  <text id="XXX0X00-Copy" fontFamily="SquadaOne-Regular, Squada One" fontSize="22.5" fontWeight="normal" letterSpacing="0.75" fill="#959595">
                    <tspan x="59.75" y="62">{ placa }</tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        : "" }
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(Plate);
