import React, { Component } from "react";

import FooterLogo from "./FooterLogo";
import TourWidget from "./TourWidget";

import '../assets/styles/footer.scss';

class Footer extends Component {

  render() {
    return (
      <footer className="footer">
        <div className="textContainer">
          <div className="brandContainer">
            <FooterLogo />
          </div>
          <div className="mobileOnly">
            <TourWidget />
          </div>
          <div className="support-links">
            <a href="mailto:contato@emplacou.com.br" className="link">contato@emplacou.com.br</a>
            <div className="link-spacer"> - </div>
            <a href="https://wa.me/555130376494?text=Olá,%20solicito%20suporte%20para%20o%20Emplacou." target="_blank" rel="noopener noreferrer" className="link -whatsapp">WhatsApp</a>
          </div>
          <p className="text">
            © Copyright 2020. Todos os direitos reservados.
          </p>
          <p className="text">
            Emplacou Inovações em Tecnologia LTDA CNPJ 37.805.809/0001-09
          </p>
          <p className="text">
            Tv. Jaguarão, nº 60 - São João - Porto Alegre, RS - CEP 90.520-070
          </p>
        </div>
        <div className="desktopOnly">
          <TourWidget />
        </div>
      </footer>
    );
  }
}

export default Footer;
