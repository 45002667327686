import React, { Component } from "react";
import { connect } from "react-redux";

class ArrowIcon extends Component {

  render() {
    const { template } = this.props;
    let fillColor = '#323232';

    if (!this.props.disabled) {
      fillColor = template.corPrimaria ? template.corPrimaria : '#FFCC00';
    }

    return (
      <svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1">
        <g id="Desk" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Home_URL" transform="translate(-369.000000, -336.000000)" fill={ fillColor } fillRule="nonzero">
            <g id="Group-3" transform="translate(109.000000, 286.000000)">
              <g id="Icone/tour-Copy-4" transform="translate(265.000000, 57.000000) rotate(-270.000000) translate(-265.000000, -57.000000) translate(257.000000, 49.000000)">
                <path d="M7.46212322,6.70307933 L4.20672863,3.52642127 C3.67995039,3.01253816 2.88724378,2.85871275 2.19862422,3.13674686 C1.51000466,3.41478096 1.06125588,4.06985112 1.06184248,4.79618714 C1.06267855,5.27296516 1.25816888,5.7298247 1.60517566,6.065953 L3.55749151,7.96835839 L1.60517566,9.87525058 C1.10151493,10.3199423 0.890115645,10.9987802 1.05522758,11.6412244 C1.22033951,12.2836687 1.73512462,12.7852934 2.39442365,12.9461843 C3.05372267,13.1070752 3.75037019,12.9010803 4.20672863,12.4102955 L7.46212322,9.23812425 C8.17929226,8.5375949 8.17929226,7.40360867 7.46212322,6.70307933 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(ArrowIcon);
