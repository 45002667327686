import React, { Component } from "react";
import SimpleBarReact from "simplebar-react";

import 'simplebar/src/simplebar.css';

class Options extends Component {

  render() {
    return (
      <SimpleBarReact className="options" style={{ maxHeight: 250 }}>
        { this.props.children }
      </SimpleBarReact>
    );
  }
}

export default Options;
