import { CITY_UPDATE, CITY_RESET } from './actionTypes';

export const updateCity = value => ({
  type: CITY_UPDATE,
  city: value
});

export const resetCity = _ => ({
  type: CITY_RESET
});
