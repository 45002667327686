import { UF_ADDRESS_UPDATE } from '../actions/actionTypes';

const initialState = {
  ufAddress: {
    id: null,
    nome: '',
    sigla: ''
  }
};

export const ufAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case UF_ADDRESS_UPDATE:
      return {
        ...state,
        ufAddress: action.ufAddress
      };
    default:
      return state;
  }
};
