import React, { Component } from "react";
import { connect } from "react-redux";

import { updateStep } from "../actions/step";
import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Steps from "../components/Steps";

import FormaDePagamentoForm from "../forms/FormaDePagamentoForm";

import '../assets/styles/intern.scss';
import '../assets/styles/forma-de-pagamento.scss';

const PAGAMENTO_URL = `pedidos/pagamento​`;
const UPLOAD_DOCUMENTO_SOLICITANTE_URL = `agendamentos/upload/`;

class FormaDePagamento extends Component {
  // Lifecycle methods
  async componentDidMount() {
    if (!this.props.pedido.id) {
      this.props.history.push("/");
    }

    await this.props.updateStep(3);
  }

  // Helpers methods
  submit = async values => {
    let dadosPagamento = {};

    dadosPagamento.idPedido = this.props.pedido.id;
    dadosPagamento.ipRemoto = "";

    if (values.inputPagamentoTipo === "2") {
      dadosPagamento.tipoPagamento = "Boleto";
    } else if (values.inputPagamentoTipo === "3") {
      dadosPagamento.tipoPagamento = "PIX";
    } else {
      dadosPagamento.tipoPagamento = "CartaoCredito";
      dadosPagamento.dadosCartao = {
        nomeTitular: values.inputPagamentoCartaoTitular,
        cpfTitular: values.inputPagamentoCartaoTitularCPF,
        numero: values.inputPagamentoCartaoNumero,
        mesExpiracao: parseInt(values.inputPagamentoCartaoVencimento.split("/")[0]),
        anoExpiracao: parseInt(values.inputPagamentoCartaoVencimento.split("/")[1]),
        cvv: parseInt(values.inputPagamentoCartaoCCV)
      };
    } 
    
    this.props.updateLoader(true);

    try {
      const pagamento = await api.post(PAGAMENTO_URL, dadosPagamento).catch(err => { throw err; });

      if (this.props.pedido.documentoSolicitante !== undefined && this.props.pedido.documentoSolicitante !== null) {
        const formData = new FormData();
        formData.append('anexo', this.props.pedido.documentoSolicitante);
        const config = {
            headers: {
              'content-type': 'multipart/form-data'
            }
        };
        await api.put(UPLOAD_DOCUMENTO_SOLICITANTE_URL + this.props.pedido.codigoAutorizacao, formData, config).catch(err => { console.log('Error upload: ', err); });
      }

      this.props.updateLoader(false);

      this.handleValidRequest(pagamento.data);
    } catch (err) {
      this.handleInvalidRequest(err);
    }

    this.props.updateLoader(false);
  }

  handleValidRequest = pagamento => {
    let newPedido = this.props.pedido;
    
    newPedido.idCobranca = pagamento.idCobranca;
    newPedido.urlCobranca = pagamento.urlCobranca;
    newPedido.status = pagamento.statusPedido;
    newPedido.tipoPagamento = pagamento.tipoPagamento;

    this.props.updatePedido(newPedido);
    this.props.history.push("./pagamento");
  }

  handleInvalidRequest = err => {
    console.log('Error Pagamento: ', err);

    if (err.response.status === 400 || err.response.status === 409 || err.response.status === 500) {
      this.props.updateErrorModal(true);
    }
  }

  render() {
    return (
      <Base hideTitle={ true }>
        <div className="internContainer -no-margin">
          <CardDetail />
          <Steps>
            <FormaDePagamentoForm onSubmit={this.submit} />
          </Steps>
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido
});

const mapDispatchToProps = {
  updateStep: updateStep,
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FormaDePagamento);
