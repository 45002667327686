import React, { Component } from "react";
import { Link } from "react-router-dom";

import { formatCNPJ } from "../helpers";

import '../assets/styles/card-estampador.scss';

class CardEstampador extends Component {
  render() {
    return (
      <Link
        to={`/${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`}
        className="card">
        { this.props.estampador.logo
          ? <img src={ this.props.estampador.logo } alt={ this.props.estampador.nome } className="logo" />
          : <div className="logo -disabled">Logo</div>
        }
        <div className="name">{ this.props.estampador.nome }</div>
        <div className="field cnpj">CNPJ { formatCNPJ(this.props.estampador.cnpj) }</div>
        { this.props.estampador.realizaEntrega ?
        <div className="field address">A distância máxima de entrega é de <span className="km">{ this.props.estampador.entregaKilometragemMaxima } Km </span>
          ao redor do endereço da loja.
        </div>
        : '' }
        { this.props.estampador.endereco ?
          <div className="field address">{ this.props.estampador.endereco }</div>
        : ''}
      </Link>
    );
  }
}

export default CardEstampador;
