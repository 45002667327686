import { createTextMask } from 'redux-form-input-masks';

export const formatCPF = cpf => cpf ? cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : null;
export const formatCNPJ = cnpj => cnpj ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : null;
export const formatCEP = cep => cep ? cep.replace(/^(\d{5})(\d{3})/, "$1-$2") : null;
export const formatPhone = phone => phone ? phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2.$3") : null;
export const formatRealValue = value => value ? (value).toFixed(2).replace(`.`, `,`) : null;

export const cpfMask = createTextMask({
  pattern: '999.999.999-99',
  guide: false
});

export const cnpjMask = createTextMask({
  pattern: '99.999.999/9999-99',
  guide: false
});

export const phoneMask = createTextMask({
  pattern: '(99) 99999.9999',
  guide: false,
  allowEmpty: true
});

export const cepMask = createTextMask({
  pattern: '99.999-999',
  guide: false
});

export const creditCardMask = createTextMask({
  pattern: '9999 9999 9999 9999',
  guide: false
});
creditCardMask.autoComplete = "cc-number";

export const cardExpirationMask = createTextMask({
  pattern: '99/9999',
  guide: false,
  stripMask: false
});
cardExpirationMask.autoComplete="cc-exp"

export const cardCCVMask = createTextMask({
  pattern: '999',
  guide: false
});
cardCCVMask.autoComplete="cc-csc";

export const redirectToStep = (state, history, path) => {
  switch (state) {
    case "ConsultaAutorizacao":
      history.push(`/${path}/consulta-emplacamento`);
      break;
    case "InformacoesNota":
      history.push(`/${path}/forma-de-pagamento`);
      break;
    case "DefinirFormaPagamento":
      history.push(`/${path}/forma-de-pagamento`);
      break;
    case "AguardandoPagamento":
      history.push(`/${path}/pagamento`);
      break;
    case "PagamentoConfirmado":
      history.push(`/${path}/pagamento`);
      break;
    case "PagamentoCancelado":
      history.push(`/${path}/pedido-cancelado`);
      break;
    case "AgendarInstalacao":
      history.push(`/${path}/agendamento`);
      break;
    case "Finalizado":
      history.push(`/${path}/resumo`);
      break;
    case "Cancelado":
      history.push(`/${path}/pedido-cancelado`);
      break;
    default:
      history.push(`/${path}/autorizacao-invalida`);
      break;
  }
}

export const getDayOfTheWeek = day => {
  let weekDayAsText;

  switch (day) {
    case 0:
      weekDayAsText = "Domingo";
      break;
    case 1:
      weekDayAsText = "Segunda";
      break;
    case 2:
      weekDayAsText = "Terça";
      break;
    case 3:
      weekDayAsText = "Quarta";
      break;
    case 4:
      weekDayAsText = "Quinta";
      break;
    case 5:
      weekDayAsText = "Sexta";
      break;
    case 6:
      weekDayAsText = "Sábado";
      break;
    default:
      weekDayAsText = "Domingo";
      break;
  }

  return weekDayAsText;
}

export const validateCardNumber = number => {
  const regex = new RegExp('^[0-9]{16}$');
  if (!regex.test(number)) return false;

  let sum = 0;
  for (let i = 0; i < number.length; i += 1) {
    let intVal = parseInt(number.substr(i, 1), 10);
    if (i % 2 === 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal = 1 + intVal % 10;
      }
    }
    sum += intVal;
  }
  return sum % 10 === 0 ? 'Cartao invalido' : undefined;
};

export const validateEmail = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'E-mail invalido' : undefined;

export const validateCPF = val => {
  if (val) {
    let cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] !== cpf[i]) {
        aux = true;
      }
    }

    if (aux === false) {
      return 'CPF invalido';
    }

    for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = ((v1 * 10) % 11);

    if (v1 === 10) {
      v1 = 0;
    }

    if (v1 !== parseInt(cpf[9])) {
      return 'CPF invalido';
    }

    for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = ((v2 * 10) % 11);

    if (v2 === 10) {
      v2 = 0;
    }

    if (v2 !== parseInt(cpf[10])) {
      return 'CPF invalido';
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export const validateCNPJ = val => {
  if (val) {
    let cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] !== cnpj[i]) {
        aux = true;
      }
    }

    if (aux === false) {
      return 'CNPJ invalido';
    }

    for (let i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = (v1 % 11);

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = (11 - v1);
    }

    if (v1 !== parseInt(cnpj[12])) {
      return 'CNPJ invalido';
    }

    for (let i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = (v2 % 11);

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = (11 - v2);
    }

    if (v2 !== parseInt(cnpj[13])) {
      return 'CNPJ invalido';
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export const validateChassi = (val) => {
  
  let vin = val.trim();
  
  if (vin.length !== 17) {
    return 'Chassi deve conter 17 dígitos';
  }
  
  if (vin.match(/^0/) != null) {
    return 'Primeiro dígito não pode ser zero';
  }

  if (vin.match(/ /) != null) {
    return 'Não pode conter espaço no chassi';
  }

  const chassiDesmenbrado = vin.substring(4,17)
  const chassiArray = chassiDesmenbrado.split('');
  let repetidos = 0;
  for (let i = 0; i < chassiArray.length; i++) {
    if (repetidos > 6)
      return 'Não pode repetir mais de 6 vezes o mesmo caracter';

    let digitoA = chassiArray[i];
    repetidos = 0;

    chassiArray.forEach(digitoB => {
      if (digitoA === digitoB) {
        repetidos++;
      }
      if (repetidos > 6)
        return;
    });
  }
  
  if (vin.match(/[iIoOqQ]/) != null) {
    return 'O chassi não pode conter os caracteres I,O e Q';
  }
  
  if (vin.match(/[0-9]{4}$/) === null) {
    return 'Os 4 últimos caracteres devem ser numéricos';
  }
    
  return '';
}