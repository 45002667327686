import React, { Component } from "react";
import { connect } from "react-redux";

import Options from "../components/Options";

import '../assets/styles/select.scss';

class SelectCidade extends Component {
  state = {
    focused: false,
    citiesAddress: [],
    search: ''
  }

  // Event handler methods
  handleSelectFocus = _ => {
    this.setState(state => ({
      focused: !state.focused,
      citiesAddress: this.props.citiesAddress,
      search: ''
    }));
  }

  handleInputChange = e => {
    const citiesAddress = this.props.citiesAddress.filter(city => city.nome.toLowerCase().includes(e.target.value.toLowerCase()));

    this.setState({
      citiesAddress: citiesAddress,
      search: e.target.value
    });
  }

  render() {
    const { cityAddress, citiesAddress, template } = this.props;
    const classNames = "select" + (this.state.focused ? " -focused" : "") + (citiesAddress.length === 0 ? " -disabled" : "");
    const borderStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className={ classNames }>
        <div className="border" style={ borderStyle }></div>
        <div className="label">Cidade *</div>
        <div className="field" onClick={ _ => this.handleSelectFocus() }>
          <input
            className={"selected " + (cityAddress.id === null ? "-empty" : "")}
            onChange={ e => this.handleInputChange(e) }
            placeholder={ cityAddress.id === null ? 'Selecione' : cityAddress.nome }
            value={ this.state.search }>
          </input>
          <Options>
            { this.state.citiesAddress.map((city, key) => (
              <div
                className="option"
                key={ key }
                data-id={ city.id }
                data-nome={ city.nome }
                onClick={ e => this.props.onClick(e) }>
                { city.nome }
              </div>
            ))}
          </Options>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  cityAddress: store.cityAddressState.cityAddress,
  citiesAddress: store.citiesAddressState.citiesAddress,
  template: store.templateState.template
});

export default connect(mapStateToProps)(SelectCidade);
