import React, { Component } from "react";
import { withRouter } from 'react-router'
import { connect } from "react-redux";

import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";

import { api } from "../services/api";

import TextField from "./TextField";
import Button from "./Button";

import { redirectToStep } from "../helpers";

import '../assets/styles/solicitacao.scss';

const PEDIDO_URL = `pedidos/estampador/{idEstampador}/codigodetran/{codigoDetran}`;

class SolicitacaoDetran extends Component {
  state = {
    codigoDetran: '',
    invalidCodigoDetranSize: true
  }

  // Update state methods
  setCodigoDetran = e => {
    this.setState({
      codigoDetran: e.target.value,
      invalidCodigoDetranSize: e.target.value.length === 0
    });
  }

  // Fetch data methods
  fetchPedido = async _ => {
    let url = PEDIDO_URL;
    url = url.replace(`{idEstampador}`, this.props.estampador.id).replace(`{codigoDetran}`, this.state.codigoDetran);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.handleValidAutorizacao(pedido.data);
    } catch (err) {
      this.handleInvalidAutorizacao(err);
    }

    this.props.updateLoader(false);
  }

  // Helper methods
  handleValidAutorizacao = pedido => {
    this.props.updatePedido(pedido);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidAutorizacao = err => {
    if (err.response.status === 404 || err.response.status === 409 || err.response.status === 500) {
      const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

      this.props.history.push(`/${path}/autorizacao-invalida`);
    }
  }

  render() {
    const { template } = this.props;
    const backgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : { backgroundColor: "#FFCC00" };
    const labelCodigoAutDetran = `Número de autorização do DETRAN-${this.props.estampador.uf}`;

    return (
      <div className="solicitacao">
        <div className="container">
          <div className="title">
            Solicite seu emplacamento online e agende a instalação de sua placa!
          </div>
          <p className="text">
            É simples e fácil. Preencha o código de autorização do DETRAN-{this.props.estampador.uf} e clique em iniciar solicitação.
          </p>
          <p className="text">
            <span className="important">Importante: </span>A cobertura máxima de entrega deste estampador é de: <span className="important">{ this.props.estampador.entregaKilometragemMaxima } Km </span>
            ao redor do endereço da loja: <span className="important"> {this.props.estampador.endereco }. </span> Caso você deseje receber o seu pedido fora da cobertura máxima, selecione outro estampador.
          </p>  
          <div className="form -singleColumn">
            <TextField
              name={labelCodigoAutDetran}
              text="000000000000000"
              minLength={24}
              maxLength={24}
              onChange={ e => this.setCodigoDetran(e) } />
            <Button
              type="-yellow"
              text="Iniciar solicitação"
              template={ template }
              disabled={ this.state.invalidCodigoDetranSize }
              onClick={ _ => this.fetchPedido() } />
          </div>
        </div>
        <div className="figure">
          <div className="bg" style={ backgroundStyle }></div>
          <div className="img"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updatePedido: updatePedido,
  updateLoader: updateLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SolicitacaoDetran));
