import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { updateStep } from "../actions/step";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Steps from "../components/Steps";
import Offers from "../components/Offers";
import Button from "../components/Button";

import '../assets/styles/intern.scss';

class ConsultaEmplacamento extends Component {
  // Lifecycle methods
  async componentDidMount() {
    if (!this.props.pedido.id) {
      this.props.history.push("/");
    }

    await this.props.updateStep(1);
  }

  render() {
    const { pedido, template, estampador } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <Base hideTitle={ true }>
        <div className="internContainer -no-margin">
          <CardDetail />
          <Steps>
            <h3 className="title">
              Seu pedido
              <div className="detail" style={ detailStyle }></div>
            </h3>
            <p className="text">
              Sua autorização foi validada. Seu nº de placa é <span className="bold">{ pedido.placa }</span>.
            </p>
            
            { pedido.formaEntrega === 'Entrega' ?
            <p className="text">
              <span className="bold">Importante: </span>A cobertura máxima de entrega deste estampador é de: <span className="bold">{ estampador.entregaKilometragemMaxima } Km </span>
              ao redor do endereço da loja: <span className="bold"> { estampador.endereco }. </span> Caso você deseje receber o seu pedido fora da cobertura máxima, selecione outro estampador.
            </p>   
            : '' }

            <Offers />
            <div className="action-bar">
              <Link to={`./preencher-informacoes`} style={{ textDecoration: 'none' }}>
                <Button
                  type="-yellow"
                  text="Avançar"
                  template={ template }  />
              </Link>
            </div>
          </Steps>
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador,
  template: store.templateState.template,
  pedido: store.pedidoState.pedido
});

const mapDispatchToProps = {
  updateStep: updateStep
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaEmplacamento);
