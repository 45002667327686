import React, { Component } from "react";
import { connect } from "react-redux";

import { updateTourModal } from "../actions/tourModal";

import AlertIcon from "../embedded/AlertIcon";

import '../assets/styles/tour-widget.scss';

class TourWidget extends Component {

  render() {
    const { template } = this.props;
    const containerStyle = template.corSecundaria ? {
      backgroundColor: template.corSecundaria,
      border: `1px solid ${template.corSecundaria}`
    } : {};
    const textStyle = template.corPrimaria && template.corSecundaria ? { color: '#323232' } : {};

    return (
      <div className="tourWidget" style={ containerStyle } onClick={ _ => this.props.updateTourModal(true) }>
        <AlertIcon />
        <div className="title" style={ textStyle }>Está perdido?</div>
        <div className="subtitle" style={ textStyle }>Confira o tour da plataforma</div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateTourModal: updateTourModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TourWidget);
