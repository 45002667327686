import React, { Component } from "react";
import { connect } from "react-redux";

import { updateEstampadores } from "../actions/estampadores";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Button from "../components/Button";

const SEARCH_BY_GEOLOCATION_URL = `estampadores`;

class ButtonLocation extends Component {
  // Event handler methods
  onClickGeolocation = () => {
    navigator.geolocation.getCurrentPosition(async position => {
      this.props.updateLoader(true);

      try {
        const estampadores = await api.get(SEARCH_BY_GEOLOCATION_URL, {
          params: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        }).catch(err => { throw err; });

        this.props.updateLoader(false);

        this.props.updateEstampadores(estampadores.data);
      } catch (err) {
        this.props.updateErrorModal(true);
      }

      this.props.updateLoader(false);
    });
  }

  render() {
    return (
      <Button
        type="-location"
        text="Usar localização atual"
        disabled={"geolocation" in navigator ? false : true}
        onClick={e => this.onClickGeolocation(e)} />
    );
  }
}

const mapDispatchToProps = {
  updateEstampadores: updateEstampadores,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(null, mapDispatchToProps)(ButtonLocation);
