import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import logoDefault from '../assets/img/logo.svg';

class HeaderLogo extends Component {

  render() {
    const { uf, estampador, group, template } = this.props;

    let logo = logoDefault;
    let link = `/`;

    if (template.corPrimaria) {
      if (group.logo) {
        logo = group.logo;
      } else if (estampador.logo) {
        logo = estampador.logo;
      }

      if (group.url) {
        link += `${uf.sigla}/grupo/${group.url}`;
      } else if (estampador.url) {
        link += `${estampador.uf.toLowerCase()}/${estampador.url}`;
      }
    }

    return (
      <Link to={ link }>
        <img src={ logo } alt="Emplacou" className="logo" />
      </Link>
    );
  }
}

const mapStateToProps = store => ({
  uf: store.ufState.uf,
  estampador: store.estampadorState.estampador,
  group: store.groupState.group,
  template: store.templateState.template
});

export default connect(mapStateToProps)(HeaderLogo);
