import React, { Component } from "react";
import { connect } from "react-redux";

import CardEstampador from "./CardEstampador";
import Button from "./Button";

import '../assets/styles/list-results.scss';

const RESULTS_PER_PAGE = 9;

class ListResults extends Component {
  state = {
    visible: RESULTS_PER_PAGE
  }

  // Update state methods
  showMoreResults = () => {
    this.setState(state => {
      return { visible: state.visible + RESULTS_PER_PAGE };
    });
  }

  render() {
    const { estampadores } = this.props;

    return (
      <div className="listResults">
        <p className="text">
          Selecione um estabelecimento para solicitar a sua placa
        </p>
        <div className="list">
          { estampadores.slice(0, this.state.visible).map((item, key) => <CardEstampador estampador={ item } key={ key } />) }
        </div>
        <Button
          type="-dark"
          text="Carregar mais resultados"
          disabled={ estampadores.length < this.state.visible }
          onClick={ (e) => this.showMoreResults(e) } />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  estampadores: store.estampadoresState.estampadores
});

export default connect(mapStateToProps)(ListResults);
