import React, { Component } from "react";
import { withRouter } from 'react-router'
import { connect } from "react-redux";

import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";

import { api } from "../services/api";

import TextField from "./TextField";
import Button from "./Button";

import { redirectToStep, validateChassi } from "../helpers";

import '../assets/styles/solicitacao.scss';

const PEDIDO_URL = `pedidos/estampador/{idEstampador}/autorizacao​/{autorizacao}/chassi/{chassi}`;

class SolicitacaoChassi extends Component {
  state = {
    autorizacao: '',
    autorizacaoInvalida: true,
    chassi: '',
    chassiErrors: '',
    ChassiInvalido: true
  }

  // Update state methods
  setAutorizacao = e => {
    if (e.target.value.length === 15) {
      this.setState({
        autorizacao: e.target.value,
        autorizacaoInvalida: false
      });
    } else {
      this.setState({
        autorizacaoInvalida: true
      });
    }
  }

  // Update state methods
  setChassi = e => {
    e.target.value = e.target.value.toUpperCase();
    let resultValidate = validateChassi(e.target.value);

    if (resultValidate === '') {
      this.setState({
        chassi: e.target.value,
        ChassiInvalido: false,
        chassiErrors: ''
      });
    } else {
      this.setState({
        ChassiInvalido: true,
        chassiErrors: resultValidate
      });
    }
  }

  // Fetch data methods
  fetchPedido = async _ => {
    let url = PEDIDO_URL;
    url = url.replace(`{idEstampador}`, this.props.estampador.id).replace(`{autorizacao}`, this.state.autorizacao).replace(`{chassi}`, this.state.chassi);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.handleValidAutorizacao(pedido.data);
    } catch (err) {
      this.handleInvalidAutorizacao(err);
    }

    this.props.updateLoader(false);
  }

  // Helper methods
  handleValidAutorizacao = pedido => {
    this.props.updatePedido(pedido);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidAutorizacao = err => {
    if (err.response.status === 404 || err.response.status === 409 || err.response.status === 500) {
      const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

      this.props.history.push(`/${path}/autorizacao-invalida`);
    }
  }

  render() {
    const { template } = this.props;
    const backgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : { backgroundColor: "#FFCC00" };

    return (
      <div className="solicitacao">
        <div className="container">
          <div className="title">
            Solicite seu emplacamento online e agende a instalação de sua placa!
          </div>
          <p className="text">
            É simples e fácil. Preencha o número de autorização do Senatran e o número do Chassi e então clique em iniciar solicitação.
          </p>
          <p className="text">
            <span className="important">Importante: </span>A cobertura máxima de entrega deste estampador é de: <span className="important">{ this.props.estampador.entregaKilometragemMaxima } Km </span>
            ao redor do endereço da loja: <span className="important"> {this.props.estampador.endereco }. </span> Caso você deseje receber o seu pedido fora da cobertura máxima, selecione outro estampador.
          </p>  
          <div className="form -singleColumn">
            <TextField
              name="Número de autorização do Senatran"
              text="000000000000000"
              minLength={15}
              maxLength={15}
              onChange={ e => this.setAutorizacao(e) } />
            <TextField
              name="Número do Chassi"
              text="Digite aqui"
              minLength={17}
              maxLength={17}
              onChange={ e => this.setChassi(e) } />
              <p style={{ color: '#D32F2F', paddingBottom: '10px', minHeight: '26px' }}>{this.state.chassiErrors}</p>
            <Button
              type="-yellow"
              text="Iniciar solicitação"
              template={ template }
              disabled={ this.state.autorizacaoInvalida || this.state.ChassiInvalido }
              onClick={ _ => this.fetchPedido() } />
          </div>
        </div>
        <div className="figure">
          <div className="bg" style={ backgroundStyle }></div>
          <div className="img"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updatePedido: updatePedido,
  updateLoader: updateLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SolicitacaoChassi));