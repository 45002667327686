import React, { Component } from "react";
import { connect } from "react-redux";

import { updateStep } from "../actions/step";
import { updateAgendamento } from "../actions/agendamento";
import { updateLoader } from "../actions/loader";
import { updateErrorModal,setMessageErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Steps from "../components/Steps";

import AgendamentoForm from "../forms/AgendamentoForm";

import {formatCEP } from "../helpers";

import '../assets/styles/intern.scss';
import '../assets/styles/agendamento.scss';

const AGENDAMENTO_URL = `agendamentos`;

class Agendamento extends Component {
  // Lifecycle methods
  async componentDidMount() {
    if (!this.props.pedido.id) {
      this.props.history.push("/");
    }

    await this.props.updateStep(5);
  }

  // Helpers methods
  submit = async values => {
    let newAgendamento = this.props.agendamento;

    newAgendamento.idPedido = this.props.pedido.id;

    if (values.inputAgendamentoLocal === "2") {
      newAgendamento.idEndereco = this.props.estampador.idEndereco;
      newAgendamento.enderecoCompleto = this.props.estampador.endereco;
    } else {
      newAgendamento.cep = values.inputAgendamentoCEP;
      newAgendamento.logradouro = values.inputAgendamentoEndereco;
      newAgendamento.numero = values.inputAgendamentoNumero;
      newAgendamento.complemento = values.inputAgendamentoComplemento;
      newAgendamento.bairro = values.inputAgendamentoBairro;
      newAgendamento.idCidade = this.props.cityAddress.id;
      newAgendamento.enderecoCompleto = `${newAgendamento.logradouro}, ${newAgendamento.numero}, ${newAgendamento.complemento ? `${newAgendamento.complemento}, ${newAgendamento.bairro}` : `${newAgendamento.bairro}`}, ${formatCEP(newAgendamento.cep)}, ${this.props.cityAddress.nome}, ${this.props.cityAddress.siglaUf}`;
    }

    this.props.updateLoader(true);

    try {
      await api.post(AGENDAMENTO_URL, newAgendamento)
      .then(response => response.data)
      .catch(err => {throw err;});

      this.props.updateAgendamento(newAgendamento);
      this.props.updateLoader(false);

      this.props.history.push("./resumo");
    } catch (err) {
      console.log('Error Agendamento: ', err);

      if (err.response.status === 400 || err.response.status === 500) {;
        this.props.updateErrorModal(true);
        this.props.setMessageErrorModal(err.response.data.errors[0])
      }
    }

    this.props.updateLoader(false);
  }

  render() {
    return (
      <Base hideTitle={ true }>
        <div className="internContainer -no-margin">
          <CardDetail />
          <Steps>
            <AgendamentoForm onSubmit={ this.submit } />
          </Steps>
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  cityAddress: store.cityAddressState.cityAddress,
  pedido: store.pedidoState.pedido,
  agendamento: store.agendamentoState.agendamento,
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updateStep: updateStep,
  updateAgendamento: updateAgendamento,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal,
  setMessageErrorModal: setMessageErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Agendamento);
