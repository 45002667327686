import { ESTAMPADOR_UPDATE, ESTAMPADOR_RESET } from '../actions/actionTypes';

const initialState = {
  estampador: {
    id: null,
    nome: '',
    cnpj: '',
    url: '',
    logo: '',
    corPrimaria: '',
    corSecundaria: '',
    corTexto: '',
    email: '',
    telefone: '',
    endereco: '',
    tipoNota: '',
    uf: '',
    cidade: '',
    ibgeCidade: '',
    idEndereco: null,
    entregaKilometragemMaxima: null,
    realizaEntrega: false
  }
};

export const estampadorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ESTAMPADOR_UPDATE:
      return {
        ...state,
        estampador: action.estampador
      };
    case ESTAMPADOR_RESET:
      return initialState;
    default:
      return state;
  }
};
