import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import RenderField from "../components/RenderField";

import { cpfMask, cnpjMask, phoneMask, validateCPF, validateCNPJ, validateEmail } from "../helpers";

import '../assets/styles/intern.scss';
import '../assets/styles/text-field.scss';
import '../assets/styles/radio-field.scss';
import '../assets/styles/check-field.scss';

class DadosProprietario extends Component {
  state = {
    checked: "1",
    reuse: false,
    readonlyProprietarioName: false,
    readonlyProprietarioDocumento: false
  }

  // Lifecycle methods
  async componentDidMount() {
    const { pedido } = this.props;

    if (pedido.proprietario !== undefined && pedido.proprietario !== null) {
      if (pedido.proprietario.nome !== "") {
        this.props.change('inputProprietarioName', pedido.proprietario.nome);
        this.setState({ readonlyProprietarioName: true });
      }

      if (pedido.proprietario.tipoPessoa === "Fisica" && pedido.proprietario.documento.length === 11) {
        this.props.change('inputProprietarioCPF', pedido.proprietario.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4"));
        this.setState({ readonlyProprietarioDocumento: true });
      }

      if (pedido.proprietario.tipoPessoa === "Juridica" && pedido.proprietario.documento.length === 14) {
        this.props.change('inputProprietarioCNPJ', pedido.proprietario.documento.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1 $2 $3/$4-$5"));
        this.setState({
          checked: "2",
          readonlyProprietarioDocumento: true
        });
      }

      this.props.change('inputProprietarioPhone', pedido.proprietario.telefone);
    }
  }

  // Update state methods
  updateChecked = e => {
    if (this.state.readonlyProprietarioDocumento === true)
      return;

    this.setState({
      checked: e.target.value
    });
  }

  updateReuse = _ => {
    if (this.state.readonlyProprietarioDocumento === true) {
      return;
    }

    this.setState({
      reuse: !this.state.reuse
    });

    if (!this.state.reuse) {
      const type = this.props.solicitanteData.type;

      this.setState({
        checked: type ? type : '1'
      });

      this.props.change('inputProprietarioType', type ? type : '1');
      this.props.change('inputProprietarioName', this.props.solicitanteData.name);
      this.props.change('inputProprietarioCPF', this.props.solicitanteData.cpf?.replaceAll('.','').replaceAll('-',''));
      this.props.change('inputProprietarioCNPJ', this.props.solicitanteData.cnpj?.replaceAll('.','').replaceAll('-',''));
      this.props.change('inputProprietarioEmail', this.props.solicitanteData.email);
      this.props.change('inputProprietarioPhone', this.props.solicitanteData.phone);
    }
  }

  render() {
    const { template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    const optionBeforeStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    const optionAfterStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className="fields">
        <h3 className="title">
          Dados do(a) Proprietário(a)
          <div className="detail" style={ detailStyle }></div>
        </h3>
        <p className="text">
          Todos os itens com * são obrigatórios
        </p>
        { this.state.readonlyProprietarioDocumento === false ?
          <div className="row">
            <div className={"checkField" + (this.state.reuse ? " -selected " : "")} onClick={ _ => this.updateReuse() }>
              <div className="before" style={ optionBeforeStyle }></div>
              Utilizar dados do(a) solicitante
              <div className="after" style={ optionAfterStyle }></div>
            </div>
          </div>
        : '' }

        { this.state.readonlyProprietarioDocumento === false ?
          <div className="row">
            <div className="radioField">
              <label className="label">Tipo de pessoa *</label>
              <div className="options">
                <label className={"option" + (this.state.checked === "1" ? " -selected " : "")}>
                  <div className="before" style={ optionBeforeStyle }></div>
                  <Field name="inputProprietarioType" component="input" type="radio" value="1" className="radioInput" onClick={ e => this.updateChecked(e) } />Pessoa Física
                  <div className="after" style={ optionAfterStyle }></div>
                </label>
                <label className={"option" + (this.state.checked === "2" ? " -selected " : "")}>
                  <div className="before" style={ optionBeforeStyle }></div>
                  <Field name="inputProprietarioType" component="input" type="radio" value="2" className="radioInput" onClick={ e => this.updateChecked(e) } />Pessoa Jurídica
                  <div className="after" style={ optionAfterStyle }></div>
                </label>
              </div>
            </div>
          </div>
        : '' }

        <div className="row">
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Nome *</div>
            <Field name="inputProprietarioName" component="input" type="text" placeholder="Digite aqui" className="field" required props={{ disabled: this.state.readonlyProprietarioName }} />
          </div>
          { this.state.checked === "1" && this.state.readonlyProprietarioDocumento === true ?
            <div className="textField">
              <div className="border" style={ detailStyle }></div>
              <label className="label">CPF *</label>
              <Field name="inputProprietarioCPF" component="input" type="text" className="field" required props={{ disabled: true }} />
            </div>
          : '' }
          { this.state.checked === "1" && this.state.readonlyProprietarioDocumento === false ?
            <Field name="inputProprietarioCPF" type="text" label="CPF *" placeholder="000.000.000-00" minLength="14" maxLength="14" component={RenderField} validate={validateCPF} {...cpfMask} style={detailStyle} required />
          : '' }
          { this.state.checked === "2" && this.state.readonlyProprietarioDocumento === true ?
            <div className="textField">
              <div className="border" style={ detailStyle }></div>
              <label className="label">CNPJ *</label>
              <Field name="inputProprietarioCNPJ" component="input" type="text" className="field" required props={{ disabled: true }} />
            </div>
          : '' }
          { this.state.checked === "2" && this.state.readonlyProprietarioDocumento === false ?
            <Field name="inputProprietarioCNPJ" type="text" label="CNPJ *" placeholder="00.000.000/0000-00" minLength="18" maxLength="18" component={RenderField} validate={validateCNPJ} {...cnpjMask} style={detailStyle} required />
          : '' }
          <Field name="inputProprietarioEmail" type="email" label="E-mail *" placeholder="Digite aqui" component={RenderField} validate={validateEmail} style={detailStyle} required />
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Telefone para contato *</div>
            <Field name="inputProprietarioPhone" component="input" type="text" placeholder="(00) 0000.0000" className="field" required {...phoneMask} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template,
  pedido: store.pedidoState.pedido
});

export default connect(mapStateToProps)(DadosProprietario);
