import React, { Component } from "react";
import { connect } from "react-redux";

import Offer from "./Offer";

import '../assets/styles/offers.scss';

class Offers extends Component {
  render() {
    const { pedido } = this.props;

    const placas = pedido.itens.filter(item => item.opcional === false);
    // let acessorios = pedido.itens.filter(item => item.opcional === true);

    return (
      <div className="offers">
        { placas.map((placa, key) =>
          <Offer key={ key } placa={ placa } />
        )}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido
});

export default connect(mapStateToProps)(Offers);
