import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { updatePedido } from "../actions/pedido";

import '../assets/styles/radio-field.scss';

class FormaEntrega extends Component {
  state = {
    checked: '2'
  }

  updateChecked = e => {
    this.setState({
      checked: e.target.value
    });

    this.props.onChange(e);
  }

  render() {
    const { template } = this.props;
    const optionBeforeStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    const optionAfterStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className="pb10">
        <div className="row">
          <div className="radioField">
            <label className="label">Opção de Instalação</label>
            <div className="options">
              <label className={"option" + (this.state.checked === "2" ? " -selected " : "")}>
                <div className="before" style={ optionBeforeStyle }></div>
                <Field name="inputAgendamentoLocal" component="input" type="radio" value="2" className="radioInput" onClick={ e => this.updateChecked(e) } />Retirada
                <div className="after" style={ optionAfterStyle }></div>
              </label>
              <label className={"option" + (this.state.checked === "1" ? " -selected " : "")}>
                <div className="before" style={ optionBeforeStyle }></div>
                <Field name="inputAgendamentoLocal" component="input" type="radio" value="1" className="radioInput" onClick={ e => this.updateChecked(e) } />Entrega
                <div className="after" style={ optionAfterStyle }></div>
              </label>
            </div>
          </div>
              
        </div>
        { this.state.checked === "1" ?
          <p className="text text-2">
            <span className="important">Importante: </span>A cobertura máxima de entrega deste estampador é de: <span className="important">{ this.props.estampador.entregaKilometragemMaxima } Km </span>
            ao redor do endereço da loja: <span className="important"> {this.props.estampador.endereco }. </span> Caso você deseje receber o seu pedido fora da cobertura máxima, selecione outro estampador.
          </p>
        : '' }
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template,
  pedido: store.pedidoState.pedido,
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updatePedido: updatePedido
}

export default connect(mapStateToProps, mapDispatchToProps)(FormaEntrega);
