import React, { Component } from "react";
import { connect } from "react-redux";

import { updateStep } from "../actions/step";
import { updateAgendamento } from "../actions/agendamento";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import InstalacaoAgendada from "../components/InstalacaoAgendada";
import OrderSummary from "../components/OrderSummary";

import '../assets/styles/intern.scss';
import '../assets/styles/summary.scss';

const AGENDAMENTO_URL = `agendamentos/estampador​/{idEstampador}​/pedido​/{idPedido}`;

class Resumo extends Component {
  // Lifecycle methods
  async componentDidMount() {
    if (!this.props.pedido.id) {
      this.props.history.push("/");
    }

    await this.validateAgendamentoData();

    await this.props.updateStep(6);
  }

  // Fetch data methods
  fetchAgendamento = async _ => {
    let url = AGENDAMENTO_URL;
    url = url.replace(`{idEstampador}`, this.props.pedido.estampador.id).replace(`{idPedido}`, this.props.pedido.id);

    this.props.updateLoader(true);

    try {
      const agendamento = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateAgendamento(agendamento.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  validateAgendamentoData = async _ => {
    if (this.props.agendamento.idPedido === null) {
      await this.fetchAgendamento();
    }
  }

  render() {
    return (
      <Base hideTitle={ true }>
        <div className="internContainer summaryContainer -no-margin">
          <CardDetail />
          <InstalacaoAgendada />
          <OrderSummary />
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido,
  agendamento: store.agendamentoState.agendamento,  
  estampador: store.estampadorState.estampador,
  uf: store.ufState.uf
});

const mapDispatchToProps = {
  updateStep: updateStep,
  updateAgendamento: updateAgendamento,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Resumo);
