export const UF_UPDATE = 'UF_UPDATE';
export const UF_RESET = 'UF_RESET';
export const UFS_UPDATE = 'UFS_UPDATE';
export const CITY_UPDATE = 'CITY_UPDATE';
export const CITY_RESET = 'CITY_RESET';
export const CITIES_UPDATE = 'CITIES_UPDATE';
export const GROUP_UPDATE = 'GROUP_UPDATE';
export const ESTAMPADOR_UPDATE = 'ESTAMPADOR_UPDATE';
export const ESTAMPADOR_RESET = 'ESTAMPADOR_RESET';
export const ESTAMPADORES_UPDATE = 'ESTAMPADORES_UPDATE';
export const ESTAMPADORES_RESET = 'ESTAMPADORES_RESET';
export const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
export const TEMPLATE_RESET = 'TEMPLATE_RESET';
export const AUTORIZACAO_UPDATE = 'AUTORIZACAO_UPDATE';
export const PEDIDO_UPDATE = 'PEDIDO_UPDATE';
export const STEP_UPDATE = 'STEP_UPDATE';
export const UF_ADDRESS_UPDATE = 'UF_ADDRESS_UPDATE';
export const UFS_ADDRESS_UPDATE = 'UFS_ADDRESS_UPDATE';
export const CITY_ADDRESS_UPDATE = 'CITY_ADDRESS_UPDATE';
export const CITIES_ADDRESS_UPDATE = 'CITIES_ADDRESS_UPDATE';
export const AGENDAMENTO_UPDATE = 'AGENDAMENTO_UPDATE';
export const LOADER_UPDATE = 'LOADER_UPDATE';
export const ERROR_MODAL_UPDATE = 'ERROR_MODAL_UPDATE';
export const ERROR_MODAL_MESSAGE = 'ERROR_MODAL_MESSAGE';
export const TOUR_MODAL_UPDATE = 'TOUR_MODAL_UPDATE';
export const COOKIE_MODAL_UPDATE = 'COOKIE_MODAL_UPDATE';
