import { ERROR_MODAL_UPDATE, ERROR_MODAL_MESSAGE } from '../actions/actionTypes';

const initialState = {
  errorModal: false,
  errorMessage: 'Revise seus dados ou tente novamente em alguns instantes'
};

export const errorModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_MODAL_UPDATE:
      return {
        ...state,
        errorModal: action.errorModal,
        errorMessage: initialState.errorMessage
      };
      case ERROR_MODAL_MESSAGE:
        return {
          ...state,
          errorMessage: action.errorMessage
        };      
    default:
      return state;
  }
};
