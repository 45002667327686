import React, { Component } from "react";
import { connect } from "react-redux";

import '../assets/styles/search-bar.scss';

class SearchBar extends Component {

  render() {
    const { template } = this.props;
    const containerStyle = template.corPrimaria ? { borderTop: `5px solid ${template.corPrimaria}` } : {};

    return (
      <div className="searchBar" style={ containerStyle }>
        { this.props.children }
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(SearchBar);
