import { STEP_UPDATE } from '../actions/actionTypes';

const initialState = {
  step: null
};

export const stepReducer = (state = initialState, action) => {
  switch (action.type) {
    case STEP_UPDATE:
      return {
        ...state,
        step: action.step
      };
    default:
      return state;
  }
};
