import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";

import { updateUFAddress } from "../actions/ufAddress";
import { updateUFsAddress } from "../actions/ufsAddress";
import { updateCityAddress } from "../actions/cityAddress";
import { updateCitiesAddress } from "../actions/citiesAddress";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import SelectEstadoAddress from "../components/SelectEstadoAddress";
import SelectCidadeAddress from "../components/SelectCidadeAddress";
import Button from "../components/Button";
import Periods from "../components/Periods";

import { cepMask } from "../helpers";

import '../assets/styles/text-field.scss';
import '../assets/styles/radio-field.scss';

const UFS_URL = `localidades/ufs`;
const CITIES_URL = `localidades/cidades/uf/{uf}`;
const CEP_URL = `localidades/endereco/cep/{cep}`;

class AgendamentoForm extends Component {
  state = {
    checked: "1",
    desabilitarOpcoesEntrega: false
  }

  // Lifecycle methods
  async componentDidMount() {
    const { pedido, estampador } = this.props;

    if (pedido.formaEntrega !== undefined) {
      let formaEntrega = pedido.formaEntrega === 'Retirada' ? '2' : '1';

      this.setState({
        checked: formaEntrega,
        desabilitarOpcoesEntrega: true
      });

      this.props.change("AgendamentoForm", "inputAgendamentoLocal", formaEntrega);
    } else if (estampador.realizaEntrega === false) {
      pedido.formaEntrega = "2"; // retirada
      
      this.setState({
        checked: pedido.formaEntrega,
        desabilitarOpcoesEntrega: true
      });

      this.props.change("AgendamentoForm", "inputAgendamentoLocal", pedido.formaEntrega);
    }
    
    this.validateData();
    await this.fetchUFs();
  }

  // Update state methods
  updateChecked = e => {
    this.setState({
      checked: e.target.value
    });
  }

  updateUF = e => {
    const uf = {
      id: e.target.dataset.id,
      nome: e.target.dataset.nome,
      sigla: e.target.dataset.sigla
    };

    const city = {
      id: null,
      nome: ""
    };

    this.props.updateUFAddress(uf);
    this.props.updateCityAddress(city);

    this.fetchCities(e.target.dataset.id);
  }

  updateCity = e => {
    const city = {
      id: e.target.dataset.id,
      nome: e.target.dataset.nome
    }

    this.props.updateCityAddress(city);
  }

  // Fetch data methods
  fetchUFs = async () => {
    this.props.updateLoader(true);

    try {
      const ufs = await api.get(UFS_URL).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateUFsAddress(ufs.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchCities = async ufId => {
    let url = CITIES_URL;
    url = url.replace('{uf}', ufId);

    this.props.updateLoader(true);

    try {
      const cities = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateCitiesAddress(cities.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchAddress = async cep => {
    let url = CEP_URL;
    url = url.replace('{cep}', cep);

    this.props.updateLoader(true);

    try {
      const address = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.updateAddress(address.data);
    } catch (err) {
      // this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  validateData = _ => {
    if (this.props.agendamento.idPedido === null) {
      const uf = {
        id: null,
        nome: '',
        sigla: ''
      };

      const city = {
        id: null,
        nome: ''
      };

      this.props.updateUFAddress(uf);
      this.props.updateCityAddress(city);
    }

    if (this.props.pedido.agendamento?.cep?.length === 8) {
      this.props.change("AgendamentoForm", "inputAgendamentoCEP", this.props.pedido.agendamento.cep);
      this.props.change("AgendamentoForm", "inputAgendamentoNumero", this.props.pedido.agendamento.numero);
      this.props.change("AgendamentoForm", "inputAgendamentoComplemento", this.props.pedido.agendamento.complemento);
      this.fetchAddress(this.props.pedido.agendamento.cep);
      this.props.agendamento.data = '';
    }
  }

  handleChangeCEP = e => {
    const cep = e.target.value.replace(".", "").replace("-", "");

    if (cep.length === 8) {
      this.fetchAddress(cep);
    }
  }

  updateAddress = async address => {
    this.props.change("AgendamentoForm", "inputAgendamentoBairro", address.bairro);
    this.props.change("AgendamentoForm", "inputAgendamentoEndereco", address.logradouro);

    const uf = this.props.ufsAddress.filter(uf => uf.id === address.ufId);
    this.props.updateUFAddress(uf[0]);

    await this.fetchCities(address.ufId);

    const city = this.props.citiesAddress.filter(city => city.id === address.ibgeCidade);
    this.props.updateCityAddress(city[0]);
  }

  render() {
    const { handleSubmit, pristine, invalid, ufAddress, cityAddress, template, agendamento, estampador } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    const optionBeforeStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    const optionAfterStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <form onSubmit={handleSubmit} className="schedulingContainer">
        <h3 className="title">
          Endereço da Instalação
          <div className="detail" style={ detailStyle }></div>
        </h3>
        { this.state.desabilitarOpcoesEntrega === false ?
        <div>
          <p className="text">
            Todos os itens com * são obrigatórios
          </p>
          <div className="row">
            <div className="radioField">
              <label className="label">Local *</label>
              <div className="options">
                <label className={"option" + (this.state.checked === "1" ? " -selected " : "")}>
                  <div className="before" style={ optionBeforeStyle }></div>
                  <Field name="inputAgendamentoLocal" component="input" type="radio" value="1" className="radioInput" onClick={ e => this.updateChecked(e) } />Meu endereço
                  <div className="after" style={ optionAfterStyle }></div>
                </label>
                <label className={"option" + (this.state.checked === "2" ? " -selected " : "")}>
                  <div className="before" style={ optionBeforeStyle }></div>
                  <Field name="inputAgendamentoLocal" component="input" type="radio" value="2" className="radioInput" onClick={ e => this.updateChecked(e) } />Loja
                  <div className="after" style={ optionAfterStyle }></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        : '' }
        { this.state.checked === "1" ?
          <div className="fields">
            <div className="row">
              <div className="textField">
                <div className="border" style={ detailStyle }></div>
                <div className="label">CEP *</div>
                <Field
                  name="inputAgendamentoCEP"
                  component="input"
                  type="text"
                  placeholder="00.000-000"
                  minLength="10"
                  maxLength="10"
                  className="field"
                  required
                  {...cepMask}
                  onChange={ e => this.handleChangeCEP(e) }
                />
              </div>
              <div className="textField">
                <div className="border" style={ detailStyle }></div>
                <div className="label">Endereço *</div>
                <Field name="inputAgendamentoEndereco" component="input" type="text" placeholder="Digite aqui" className="field" required />
              </div>
              <div className="textField -reduced">
                <div className="border" style={ detailStyle }></div>
                <div className="label">Número *</div>
                <Field name="inputAgendamentoNumero" component="input" type="number" placeholder="0000" className="field" required />
              </div>
              <div className="textField -reduced">
                <div className="border" style={ detailStyle }></div>
                <div className="label">Complemento</div>
                <Field name="inputAgendamentoComplemento" component="input" type="text" placeholder="Digite aqui" className="field" />
              </div>
              <div className="textField">
                <div className="border" style={ detailStyle }></div>
                <div className="label">Bairro *</div>
                <Field name="inputAgendamentoBairro" component="input" type="text" placeholder="Digite aqui" className="field" required />
              </div>
            </div>
            <div className="row">
              <SelectEstadoAddress onClick={ e => this.updateUF(e) } />
              <SelectCidadeAddress onClick={ e => this.updateCity(e) } />
            </div>
          </div>
        : '' }
        { this.state.checked === "2" ?
          <div className="shopAddress">
            <div className="line">{ estampador.endereco }</div>
          </div>
        : '' }
        <Periods />
        <div className="action-bar">
          <Button
            type="-yellow"
            text="Agendar"
            disabled={ pristine || invalid || agendamento.data === '' || (this.state.checked === "1" && (ufAddress.id === null || cityAddress.id === null)) }
            template={ template } />
        </div>
      </form>
    );
  }
}

const mapStateToProps = store => ({
  ufAddress: store.ufAddressState.ufAddress,
  ufsAddress: store.ufsAddressState.ufsAddress,
  cityAddress: store.cityAddressState.cityAddress,
  citiesAddress: store.citiesAddressState.citiesAddress,
  template: store.templateState.template,
  estampador: store.estampadorState.estampador,
  agendamento: store.agendamentoState.agendamento,
  pedido: store.pedidoState.pedido
});

const mapDispatchToProps = {
  updateUFAddress: updateUFAddress,
  updateUFsAddress: updateUFsAddress,
  updateCityAddress: updateCityAddress,
  updateCitiesAddress: updateCitiesAddress,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal,
  change: change
}

AgendamentoForm = connect(mapStateToProps, mapDispatchToProps)(AgendamentoForm);

export default reduxForm({ form: 'AgendamentoForm' })(AgendamentoForm);
