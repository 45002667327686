import React, { Component } from "react";
import { withRouter } from 'react-router'
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";
import { updateEstampador } from "../actions/estampador";

import { api } from "../services/api";

import TextField from "./TextField";
import Button from "./Button";

import { redirectToStep } from "../helpers";

import '../assets/styles/solicitacao.scss';

import FormaEntrega from "../forms/FormaEntrega";

const PEDIDO_URL = `pedidos/estampador/{idEstampador}/formaentrega/{formaEntrega}/codigodetran/{codigoDetran}/renavam/{renavam}/placa​/{placa}`;

class SolicitacaoDetranRenavamPlaca extends Component {
  state = {
    codigoDetran: '',
    renavam: '',
    placa: '',
    invalidCodigoDetranSize: true,
    invalidRenavamSize: true,
    invalidPlacaSize: true,
    formaEntrega: '2'
  }

  // Update state methods
  setCodigoDetran = e => {
    this.setState({
      codigoDetran: e.target.value,
      invalidCodigoDetranSize: e.target.value.length !== 12
    });
  }

  setRenavam = e => {
    this.setState({
      renavam: e.target.value,
      invalidRenavamSize: e.target.value.length !== 11
    });
  }

  setPlaca = e => {
    this.setState({
      placa: e.target.value.toUpperCase(),
      invalidPlacaSize: e.target.value.length !== 7
    });
  }

  setFormaEntrega = e => {
    this.setState({
      formaEntrega: e.target.value
    });
  }

  // Fetch data methods
  fetchPedido = async _ => {
    let url = PEDIDO_URL;
    url = url.replace(`{idEstampador}`, this.props.estampador.id)
              .replace(`{formaEntrega}`, this.state.formaEntrega)
              .replace(`{codigoDetran}`, this.state.codigoDetran)
              .replace(`{renavam}`, this.state.renavam)
              .replace(`{placa}`, this.state.placa);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);
      
      this.handleValidAutorizacao(pedido.data);
    } catch (err) {
      this.handleInvalidAutorizacao(err);
    }

    this.props.updateLoader(false);
  }

  // Helper methods
  handleValidAutorizacao = pedido => {
    this.props.updatePedido(pedido);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidAutorizacao = err => {
    if (err.response.status === 404 || err.response.status === 409 || err.response.status === 500) {
      const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

      this.props.history.push(`/${path}/autorizacao-invalida`);
    }
  }

  render() {
    const { template, estampador } = this.props;
    const backgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : { backgroundColor: "#FFCC00" };
    const labelCodigoAutDetran = `Número do processo do DETRAN-${estampador.uf}`;

    return (
      <div className="solicitacao">
        <div className="container">
          <div className="title">
            Solicite seu emplacamento online e agende a instalação de sua placa!
          </div>
          <p className="text">
            É simples e fácil. Preencha o número do processo do DETRAN-{estampador.uf}, o renavam e a placa do veículo e clique em iniciar solicitação.
          </p>
          <div className="form -singleColumn">
            <TextField
              name={labelCodigoAutDetran}
              text="Digite aqui"
              minLength={12}
              maxLength={12}
              onChange={ e => this.setCodigoDetran(e) } />
            <TextField
              name="Renavam"
              text="Digite aqui"
              minLength={11}
              maxLength={11}
              onChange={ e => this.setRenavam(e) } />
            <TextField
              name="Placa"
              text="Digite aqui"
              maxLength="7"
              onChange={ e => this.setPlaca(e) } />
            { estampador.realizaEntrega === true ?
              <FormaEntrega
                onChange={ e => this.setFormaEntrega(e) } />
            : '' }
            <Button
              type="-yellow"
              text="Iniciar solicitação"
              template={ template }
              disabled={ this.state.invalidCodigoDetranSize || this.state.invalidRenavamSize || this.state.invalidPlacaSize }
              onClick={ _ => this.fetchPedido() } />
          </div>
        </div>
        <div className="figure">
          <div className="bg" style={ backgroundStyle }></div>
          <div className="img"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updatePedido: updatePedido,
  updateEstampador: updateEstampador,
  updateLoader: updateLoader
}

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SolicitacaoDetranRenavamPlaca));

SolicitacaoDetranRenavamPlaca = connect(mapStateToProps, mapDispatchToProps)(withRouter(SolicitacaoDetranRenavamPlaca));

export default reduxForm({ form: 'SolicitacaoDetranRenavamPlaca' })(SolicitacaoDetranRenavamPlaca);
