import React, { Component } from "react";
import { connect } from "react-redux";

import { updateUFs } from "../actions/ufs";
import { updateEstampador } from "../actions/estampador";
import { updateTemplate } from "../actions/template";
import { updatePedido } from "../actions/pedido";
import { resetTemplate } from "../actions/template";
import { resetEstampador } from "../actions/estampador";
import { resetEstampadores } from "../actions/estampadores";
import { resetUF } from "../actions/uf";
import { resetCity } from "../actions/city";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import SearchBar from "../components/SearchBar";
import SearchResults from "../components/SearchResults";
import ConsultBar from "../components/ConsultBar";
import SelectEstado from "../components/SelectEstado";
import SelectCidade from "../components/SelectCidade";
import ButtonSearch from "../components/ButtonSearch";
import ButtonLocation from "../components/ButtonLocation";

import { redirectToStep } from "../helpers";

const UFS_URL = `localidades/ufs/?hasEstampador=true`;
const PEDIDO_BY_PLACA_URL = `pedidos/placa​/{placa}`;
const ESTAMPADOR_URL = `estampadores/`;

class Home extends Component {
  // Lifecycle methods
  async componentDidMount() {
    this.resetApp();

    await this.fetchUFs();
  }

  // Fetch data methods
  fetchUFs = async () => {
    this.props.updateLoader(true);

    try {
      const ufs = await api.get(UFS_URL).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateUFs(ufs.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchPedidoByPlaca = async placa => {
    let url = PEDIDO_BY_PLACA_URL;
    url = url.replace(`{placa}`, placa);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.handleValidPlate(pedido.data);
    } catch (err) {
      this.handleInvalidPlate(err);
    }

    this.props.updateLoader(false);
  }

  fetchEstampador = async url => {
    this.props.updateLoader(true);

    try {
      const estampador = await api.get(`${ESTAMPADOR_URL}${url}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampador(estampador.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  resetApp = _ => {
    if (this.props.template.corPrimaria) {
      this.props.resetTemplate();
    }

    if (this.props.estampador.id) {
      this.props.resetEstampador();
    }

    if (this.props.estampadores.length !== 0) {
      this.props.resetEstampadores();
    }

    if (this.props.uf.id) {
      this.props.resetUF();
    }

    if (this.props.city.id) {
      this.props.resetCity();
    }
  }

  handleValidPlate = async pedido => {
    this.props.updatePedido(pedido);

    await this.fetchEstampador(pedido.estampador.url);

    this.prepareTemplate(pedido.estampador);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidPlate = err => {
    if (err.response.status === 404) {
      this.props.history.push("/pedido-nao-encontrado");
    }
    if (err.response.status === 500) {
      this.props.updateErrorModal(true);
    }
  }

  prepareTemplate = estampador => {
    const template = {
      corPrimaria: estampador.corPrimaria,
      corSecundaria: estampador.corSecundaria,
      corTexto: estampador.corTexto ? estampador.corTexto : ''
    }

    this.props.updateTemplate(template);
  }

  render() {
    return (
      <Base>
        <SearchBar>
          <SelectEstado />
          <SelectCidade />
          <ButtonSearch />
          <div className="separator">
            <ButtonLocation />
          </div>
        </SearchBar>
        <SearchResults />
        <ConsultBar onFetchPedido={ e => this.fetchPedidoByPlaca(e) } />
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  uf: store.ufState.uf,
  city: store.cityState.city,
  estampadores: store.estampadoresState.estampadores,
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateUFs: updateUFs,
  updateEstampador: updateEstampador,
  updateTemplate: updateTemplate,
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal,
  resetEstampadores: resetEstampadores,
  resetEstampador: resetEstampador,
  resetTemplate: resetTemplate,
  resetUF: resetUF,
  resetCity: resetCity
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
