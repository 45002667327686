import { UF_UPDATE, UF_RESET } from '../actions/actionTypes';

const initialState = {
  uf: {
    id: null,
    nome: '',
    sigla: ''
  }
};

export const ufReducer = (state = initialState, action) => {
  switch (action.type) {
    case UF_UPDATE:
      return {
        ...state,
        uf: action.uf
      };
    case UF_RESET:
      return initialState;
    default:
      return state;
  }
};
