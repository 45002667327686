import React, { Component } from "react";
import { connect } from "react-redux";

import HeaderLogo from "./HeaderLogo";
import TourWidget from "./TourWidget";
import CookieModal from "./CookieModal";

import '../assets/styles/header.scss';

class Header extends Component {

  render() {
    const { template } = this.props;
    const classNames = "header" + (this.props.hideTitle ? " -reduced" : "");
    const headerStyle = template.corPrimaria && template.corSecundaria ? { backgroundImage: `linear-gradient(${template.corPrimaria}, ${template.corSecundaria})` } : {};
    const textStyle = template.corPrimaria && template.corSecundaria ? { color: '#323232' } : {};

    return (
      <header className={ classNames } style={ headerStyle }>
        <CookieModal />
        <HeaderLogo />
        { this.props.hideTitle ? '' :
          <div className="textContainer">
            <h1 className="textContainer-title" style={ textStyle }>Clicou, emplacou!</h1>
            <h2 className="textContainer-subtitle" style={ textStyle }>Agora ficou muito mais fácil emplacar o seu veículo</h2>
          </div> }
        <TourWidget />
      </header>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(Header);
