import React, { Component } from "react";

class RenderField extends Component {
  render() {
    const { input, type, label, placeholder, minLength, maxLength, required, style, meta: { touched, error, warning } } = this.props;
    const classNames = "textField" + (touched && (error || warning) ? " -error" : "");

    return (
      <div className={classNames}>
        <div className="border" style={ style }></div>
        <div className="label">{label}</div>
        <input {...input} placeholder={placeholder} minLength={minLength} maxLength={maxLength} className="field" type={type} required={required} />
      </div>
    );
  }
}

export default RenderField;
