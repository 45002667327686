import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { updatePedido } from "../actions/pedido";
import RenderField from "../components/RenderField";
import ReactTooltip from 'react-tooltip';
import { FaQuestionCircle } from 'react-icons/fa';

import { cpfMask, cnpjMask, phoneMask, validateCPF, validateCNPJ, validateEmail } from "../helpers";

import '../assets/styles/intern.scss';
import '../assets/styles/text-field.scss';
import '../assets/styles/radio-field.scss';

class DadosSolicitante extends Component {
  state = {
    checked: "1",
    readonlySolicitanteName: false,
    readonlySolicitanteDocumento: false
  }

  // Lifecycle methods
  async componentDidMount() {
    const { pedido } = this.props;

    if (pedido.solicitante !== undefined && pedido.solicitante !== null) {
      if (pedido.solicitante.nome !== undefined && pedido.solicitante.nome !== "") {
        this.props.change('inputSolicitanteName', pedido.solicitante.nome);
        this.setState({ readonlySolicitanteName: true });
      }

      if (pedido.solicitante.tipoPessoa === "Fisica" && pedido.solicitante.documento.length === 11) {
        this.props.change('inputSolicitanteCPF', pedido.solicitante.documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4"));
        this.setState({ readonlySolicitanteDocumento: true });
      }

      if (pedido.solicitante.tipoPessoa === "Juridica" && pedido.solicitante.documento.length === 14) {
        this.props.change('inputSolicitanteCNPJ', pedido.solicitante.documento.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1 $2 $3/$4-$5"));
        this.setState({
          checked: "2",
          readonlySolicitanteDocumento: true
        });
      }
      
      this.props.change('inputSolicitantePhone', pedido.solicitante.telefone);
      this.props.change('inputSolicitanteRegistration', pedido.solicitante.matricula);
    }
  }

  // Update state methods
  updateChecked = e => {
    if (this.state.readonlySolicitanteDocumento === true)
      return;

    this.setState({
      checked: e.target.value
    });
  }

  onChangeFile = async e => {
    const { pedido } = this.props;
    this.props.updatePedido(pedido);
    pedido.documentoSolicitante = e.target.files[0];
  }

  isDocumentoRequired = e => {
    const proprietarioCpf = this.props.proprietarioData.cpf;
    const solicitanteCpf = this.props.solicitanteData.cpf;
    if (proprietarioCpf === solicitanteCpf) {
      return false;
    }

    return true;
  }

  render() {
    const { template, estampador } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    const optionBeforeStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    const optionAfterStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    let labelPF = 'Pessoa Física';
    let labelPJ = 'Pessoa Jurídica';

    return (
      <div className="fields">
        <h3 className="title">
          Dados do(a) Solicitante
          <div className="detail" style={ detailStyle }></div>
        </h3>
        <p className="text">
          Todos os itens com * são obrigatórios
        </p>

        { this.state.readonlySolicitanteDocumento === false ?
          <div className="row">
            <div className="radioField">
              <label className="label">Tipo de pessoa *</label>
              <div className="options">
                <label className={"option" + (this.state.checked === "1" ? " -selected " : "")}>
                  <div className="before" style={ optionBeforeStyle }></div>
                  <Field name="inputSolicitanteType" component="input" type="radio" value="1" className="radioInput" onClick={ e => this.updateChecked(e) } />{ labelPF }
                  <div className="after" style={ optionAfterStyle }></div>
                </label>
                <label className={"option" + (this.state.checked === "2" ? " -selected " : "")}>
                  <div className="before" style={ optionBeforeStyle }></div>
                  <Field name="inputSolicitanteType" component="input" type="radio" value="2" className="radioInput" onClick={ e => this.updateChecked(e) } />{ labelPJ }
                  <div className="after" style={ optionAfterStyle }></div>
                </label>
              </div>
            </div>
          </div>
        : '' }

        <div className="row">
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <label className="label">Nome *</label>
            <Field name="inputSolicitanteName" component="input" type="text" placeholder="Digite aqui" className="field" required props={{ disabled: this.state.readonlySolicitanteName }} />
          </div>
          { this.state.checked === "1" && this.state.readonlySolicitanteDocumento === true ?
            <div className="textField">
              <div className="border" style={ detailStyle }></div>
              <label className="label">CPF *</label>
              <Field name="inputSolicitanteCPF" component="input" type="text" className="field" required props={{ disabled: true }} />
            </div>
          : '' }
          { this.state.checked === "1" && this.state.readonlySolicitanteDocumento === false ?
            <Field name="inputSolicitanteCPF" type="text" label="CPF *" placeholder="000.000.000-00" minLength="14" maxLength="14" component={RenderField} validate={validateCPF} {...cpfMask} style={detailStyle} required props={{ disabled: this.state.readonlySolicitanteDocumento }} />
          : '' }
          { this.state.checked === "2" && this.state.readonlySolicitanteDocumento === true ?
            <div className="textField">
              <div className="border" style={ detailStyle }></div>
              <label className="label">CNPJ *</label>
              <Field name="inputSolicitanteCNPJ" component="input" type="text" className="field" required props={{ disabled: true }} />
            </div>
          : '' }
          { this.state.checked === "2" && this.state.readonlySolicitanteDocumento === false ?
            <Field name="inputSolicitanteCNPJ" type="text" label="CNPJ *" placeholder="00.000.000/0000-00" minLength="18" maxLength="18" component={RenderField} validate={validateCNPJ} {...cnpjMask} style={detailStyle} required props={{ disabled: this.state.readonlySolicitanteDocumento }} />
          : '' }
          <Field name="inputSolicitanteEmail" type="email" label="E-mail *" placeholder="Digite aqui" component={RenderField} validate={validateEmail} style={detailStyle} required />
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Telefone para contato *</div>
            <Field name="inputSolicitantePhone" component="input" type="text" placeholder="(00) 0000.0000" className="field" required {...phoneMask} />
          </div>
        </div>
        { this.state.checked === "2" && (estampador.uf === 'ES') ?
        <div className="row">
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <label className="label">Matrícula *</label>
            <Field name="inputSolicitanteRegistration" component="input" type="text" placeholder="Digite aqui" className="field" required />
          </div>
        </div>
        : '' }
        { estampador.uf === 'BA' || estampador.uf === 'ES' || estampador.uf === 'PA' || estampador.uf === 'CE' || estampador.uf === 'MT' || estampador.uf === 'RN' ?
        <div className="row">
          <p className="text">
            <span className="bold">Importante: </span>Caso você esteja representando o proprietário do veículo, é preciso <a href="https://bucketsistemacbm.blob.core.windows.net/static/procuracao/PR/procuracao_emplacamento_veicular_emplacou_srp_cbm.docx" target="_blank" rel="noopener noreferrer" className="warrant">baixar</a> o modelo de procuração e apresentar na entrega do pedido.
          </p>
        </div>
        : '' }
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template,
  pedido: store.pedidoState.pedido,
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updatePedido: updatePedido
}

export default connect(mapStateToProps, mapDispatchToProps)(DadosSolicitante);
