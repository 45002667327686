import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { updateStep } from "../actions/step";
import { updatePedido } from "../actions/pedido";

import { api } from "../services/api";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Steps from "../components/Steps";
import Button from "../components/Button";

import '../assets/styles/intern.scss';
import '../assets/styles/pagamento.scss';

const PEDIDO_BY_ESTAMPADOR_URL = `pedidos/placa​/{placa}/?idEstampador={idEstampador}`;

class Pagamento extends Component {
  // Lifecycle methods
  async componentDidMount() {
    const { pedido} = this.props;

    if (!pedido.id) {
      this.props.history.push("/");
    }

    await this.props.updateStep(4);

    if (pedido.status === "AguardandoPagamento") {
      await this.refreshPedidoStatus();
    }
  }

  refreshPedidoStatus = async () => {
    let url = PEDIDO_BY_ESTAMPADOR_URL;
    url = url.replace(`{placa}`, this.props.pedido.placa).replace(`{idEstampador}`, this.props.estampador.id);

    var refreshIntervalId = setInterval(async () => {
      const pedido = await api.get(url).catch(err => { throw err; });
      
      if (pedido.data.status !== this.props.pedido.status) {
        clearInterval(refreshIntervalId);

        this.props.updatePedido(pedido.data);
      }
    }, 10000);
  }

  render() {
    const { pedido, template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <Base hideTitle={ true }>
        <div className="internContainer -no-margin">
          <CardDetail />
          <Steps>
            { pedido.status === "AguardandoPagamento" && pedido.tipoPagamento === "CartaoCredito" ?
              <div className="paymentFeedbackMessage">
                <h3 className="title">
                  Aguardando confirmação
                  <div className="detail" style={ detailStyle }></div>
                </h3>
                <p className="text">
                  <span className="bold">Estamos aguardando</span> a confirmação de pagamento pela bandeira do seu cartão. Isso pode levar alguns minutos. Após a confirmação você poderá agendar a instalação.
                </p>
              </div>
            : ''}
            { pedido.status === "AguardandoPagamento" && pedido.tipoPagamento === "Boleto" ?
              <div className="paymentFeedbackMessage">
                <h3 className="title">
                  Aguardando confirmação
                  <div className="detail" style={ detailStyle }></div>
                </h3>
                <p className="text">
                  <span className="bold">Estamos aguardando</span> a confirmação de pagamento que pode levar até <span className="bold">48 horas</span>. Após a confirmação você poderá agendar a instalação.
                </p>
                <p className="text -spacing">
                  Se precisar, baixe novamente o boleto.
                </p>
                <div className="action-bar">
                  <a href={ pedido.urlCobranca } target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button
                      type="-dark"
                      text="Baixar o boleto" />
                  </a>
                </div>
              </div>
            : ''}
            { pedido.status === "AguardandoPagamento" && pedido.tipoPagamento === "PIX" ?
              <div className="paymentFeedbackMessage">
                <h3 className="title">
                  Aguardando confirmação
                  <div className="detail" style={ detailStyle }></div>
                </h3>
                <p className="text">
                  <span className="bold">Estamos aguardando</span> a confirmação de pagamento. Após a confirmação você poderá agendar a instalação.
                </p>
                <p className="text -spacing">
                  Se precisar, baixe novamente o QR Code do PIX.
                </p>
                <div className="action-bar">
                  <a href={ pedido.urlCobranca } target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button
                      type="-dark"
                      text="Baixar QR Code PIX" />
                  </a>
                </div>
              </div>
            : ''}
            { pedido.status === "PagamentoConfirmado" || pedido.status === "AgendarInstalacao" ?
              <div className="paymentFeedbackMessage">
                <h3 className="title">
                  Seu pagamento foi confirmado com sucesso!
                  <div className="detail" style={ detailStyle }></div>
                </h3>
                <p className="text">
                  Clique em avançar para agendar a instalação.
                </p>
                <div className="action-bar">
                  <Link to={`./agendamento`} style={{ textDecoration: 'none' }}>
                    <Button
                      type="-yellow"
                      text="Avançar"
                      template={ template } />
                  </Link>
                </div>
              </div>
            : ''}
          </Steps>
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template,
  pedido: store.pedidoState.pedido,
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updateStep: updateStep,
  updatePedido: updatePedido
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagamento);
