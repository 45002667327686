import React, { Component } from "react";
import { connect } from "react-redux";

import { updateCookieModal } from "../actions/cookieModal";

import CookieConsent, { Cookies } from "react-cookie-consent";

class CookieModal extends Component {

  render() {
    return (
        <CookieConsent 
            overlay
            overlayStyle={{ backgroundColor: "rgba(0,0,0,0.7)" }}
            location="top"
            buttonText="Prosseguir"
            cookieName="emplacou"
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: "#ffcc00", color: "#4e503b", fontSize: "13px", fontWeight: "bold" }}
            expires={150}
        >
        <p className="aceiteCookies">O Emplacou utiliza cookies para melhorar a sua experiência de navegação. Ao prosseguir, você concorda com os nossos <a href="#" className="link">Termos de Uso</a></p>
      </CookieConsent>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateCookieModal: updateCookieModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieModal);