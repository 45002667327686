import { LOADER_UPDATE } from '../actions/actionTypes';

const initialState = {
  loader: false
};

export const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER_UPDATE:
      return {
        ...state,
        loader: action.loader
      };
    default:
      return state;
  }
};
