import React, { Component } from "react";
import { connect } from "react-redux";

import '../assets/styles/text-field.scss';

class TextField extends Component {
  state = {
    empty: true
  }

  // Event handler methods
  handleOnChange = e => {
    this.setState({
      empty: e.target.value === ''
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const { template } = this.props;
    const borderStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className="textField">
        <div className="border" style={ borderStyle }></div>
        <div className="label">{ this.props.name }</div>
        <input
          type={ this.props.type ? this.props.type : "text"}
          placeholder={ this.props.text }
          maxLength={ this.props.maxLength }
          className={"field" + (this.state.empty ? " -empty" : "")}
          required={ this.props.required }
          onChange={ (e) => this.handleOnChange(e) } />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(TextField);
