import { AGENDAMENTO_UPDATE } from '../actions/actionTypes';

const initialState = {
  agendamento: {
    idPedido: null,
    idEndereco: null,
    cep: '',
    logradouro: '',
    numero: null,
    complemento: '',
    bairro: '',
    idCidade: null,
    data: '',
    turno: ''
  }
};

export const agendamentoReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENDAMENTO_UPDATE:
      return {
        ...state,
        agendamento: action.agendamento
      };
    default:
      return state;
  }
};
