import React, { Component } from "react";
import { connect } from "react-redux";

import ListResults from "./ListResults";

import '../assets/styles/search-results.scss';

class SearchResults extends Component {

  render() {
    const { estampadores, template } = this.props;
    let counterMessage = 'Nenhum resultado encontrado';

    if (estampadores.length === 1) {
      counterMessage = '1 resultado encontrado';
    } else if (estampadores.length > 1) {
      counterMessage = `${estampadores.length} resultados encontrados`;
    }

    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <React.Fragment>
        { estampadores.length > 0 ?
          <div className="searchResults">
            <h3 className="title">
              { counterMessage }
              <div className="detail" style={ detailStyle }></div>
            </h3>
            <ListResults />
          </div>
        : '' }
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => ({
  estampadores: store.estampadoresState.estampadores,
  template: store.templateState.template
});

export default connect(mapStateToProps)(SearchResults);
