import { ESTAMPADOR_UPDATE, ESTAMPADOR_RESET } from './actionTypes';

export const updateEstampador = value => ({
  type: ESTAMPADOR_UPDATE,
  estampador: value
});

export const resetEstampador = _ => ({
  type: ESTAMPADOR_RESET
});
