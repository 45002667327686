import React, { Component } from "react";
import { connect } from "react-redux";

import Steps from "./Steps";

class InstalacaoAgendada extends Component {
  render() {
    const { template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <Steps>
        <h3 className="title">
          Instalação agendada com sucesso!
          <div className="detail" style={ detailStyle }></div>
        </h3>
        <p className="text">
          A loja entrará em contato com o solicitante para confirmar a instalação.
        </p>
      </Steps>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(InstalacaoAgendada);
