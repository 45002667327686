import React, { Component } from "react";
import { connect } from "react-redux";

import { updateAgendamento } from "../actions/agendamento";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import PeriodItem from "./PeriodItem"

const DISPONIBILIDADE_URL = `agendamentos/estampador​/{idEstampador}​/disponibilidade​`;

class Periods extends Component {
  state = {
    disponibilidade: [],
    nextWeek: [],
    selectedKey: null,
    selectedTimeKey: null
  }

  // Lifecycle methods
  async componentDidMount() {
    await this.fetchDisponibilidade();
    this.fetchNextWeek();
  }

  // Update state methods
  updateDisponibilidade = disponibilidade => {
    this.setState({
      disponibilidade: disponibilidade
    });
  }

  updateNextWeek = nextWeek => {
    this.setState({
      nextWeek: nextWeek
    });
  }

  // Fetch data methods
  fetchDisponibilidade = async () => {
    let url = DISPONIBILIDADE_URL;
    url = url.replace(`{idEstampador}`, this.props.pedido.estampador.id);

    this.props.updateLoader(true);

    try {
      const disponibilidade = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.updateDisponibilidade(disponibilidade.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchNextWeek = () => {
    let nextWeek = [];

    for(let i=0; i <= this.state.disponibilidade.length - 1; i++) {
      if (nextWeek.length < 7) {
        nextWeek.push(this.state.disponibilidade[i]);
      }
    }

    this.updateNextWeek(nextWeek);
  }

  // Handle events methods
  setPeriod = (e, key) => {
    const time = e.target.closest(".time");
    const date = e.target.closest(".period");

    this.setState({
      selectedKey: key,
      selectedTimeKey: time.dataset.key
    });

    const hour = time.children[1].innerHTML.split(":")[0];
    const min = time.children[1].innerHTML.split(":")[1];
    const rawDate = new Date(date.dataset.date);
    const fullDate = new Date(rawDate.setHours(rawDate.getHours()+3));
    const data = new Date(fullDate.setHours(hour, min)).toISOString();
    const finalDate = new Date(new Date(data).setHours(new Date(data).getHours()-3)).toISOString();

    const agendamento = {
      data: finalDate,
      turno: 'Manha'
    };

    this.props.updateAgendamento(agendamento);
  }

  render() {
    const { template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className="periodSuggestion">
        <h3 className="title">
          Sugestão de Período
          <div className="detail" style={ detailStyle }></div>
        </h3>
        <p className="text">
          Sugira um dia e horário para o atentendimento. A loja entrará em contato com o solicitante para confirmar a instalação.
        </p>
        <ul className="periods">
          { this.state.nextWeek.map((date, key) =>
            <PeriodItem
              key={ key }
              date={ date }
              selected={ this.state.selectedKey === key }
              selectedTimeKey={ this.state.selectedTimeKey }
              onClick={ e => this.setPeriod(e, key) } />
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template,
  pedido: store.pedidoState.pedido
});

const mapDispatchToProps = {
  updateAgendamento: updateAgendamento,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Periods);
