import React, { Component } from "react";
import { connect } from "react-redux";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Button from "../components/Button";

import '../assets/styles/solicitacao.scss';

class PedidoNaoEncontrado extends Component {

  render() {
    const { estampador, template } = this.props;
    const hasEstampador = estampador.id != null;
    const backgroundStyle = { backgroundColor: "#D32F2F" };

    return (
      <Base hideTitle={ hasEstampador }>
        <div className="internContainer">
          { hasEstampador ? <CardDetail /> : '' }
          <div className="solicitacao">
            <div className="container">
              <div className="title">
                Nenhum pedido foi encontrado
              </div>
              <p className="text">
                Verifique se digitou a placa corretamente.
              </p>
              <Button
                type="-yellow"
                text="Digitar novamente"
                template={ template }
                onClick={ this.props.history.goBack } />
            </div>
            <div className="figure -error">
              <div className="bg" style={ backgroundStyle }></div>
              <div className="img"></div>
            </div>
          </div>
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

export default connect(mapStateToProps)(PedidoNaoEncontrado);
