import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Options from "../components/Options";

import '../assets/styles/select.scss';

class SelectEstado extends Component {
  state = {
    focused: false,
    ufs: [],
    search: ''
  }

  // Event handler methods
  handleSelectFocus = _ => {
    this.setState(state => ({
      focused: !state.focused,
      ufs: this.props.ufs,
      search: ''
    }));
  }

  handleInputChange = e => {
    const ufs = this.props.ufs.filter(uf => uf.nome.toLowerCase().includes(e.target.value.toLowerCase()));

    this.setState({
      ufs: ufs,
      search: e.target.value
    });
  }

  render() {
    const { uf, ufs, template } = this.props;
    const classNames = "select" + (this.state.focused ? " -focused" : "") + (ufs.length === 0 ? " -disabled" : "");
    const borderStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className={ classNames }>
        <div className="border" style={ borderStyle }></div>
        <div className="label">Estado</div>
        <div className="field" onClick={ _ => this.handleSelectFocus() }>
          <input
            className={"selected " + (uf.id === null ? "-empty" : "")}
            onChange={ e => this.handleInputChange(e) }
            placeholder={ uf.id === null ? 'Selecione' : uf.nome }
            value={ this.state.search }>
          </input>
          <Options>
            { this.state.ufs.map((uf, key) => (
              <Link
                key={ key }
                to={`/${uf.sigla.toLowerCase()}`}
                className="option">
                { uf.nome }
              </Link>
            ))}
          </Options>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  uf: store.ufState.uf,
  ufs: store.ufsState.ufs,
  template: store.templateState.template
});

export default connect(mapStateToProps)(SelectEstado);
