import React, { Component } from "react";

import '../assets/styles/button.scss';

class Button extends Component {

  render() {
    const { template } = this.props;

    let classNames = "button " + (this.props.disabled ? "-disabled " : "") + this.props.type;
    const buttonStyle = template ? { backgroundColor: template.corPrimaria, color: template.corTexto } : {};

    return (
      <button
        className={ classNames }
        style={ buttonStyle }
        onClick={ this.props.onClick }>
        { this.props.text }
      </button>
    );
  }
}

export default Button;
