import { CITY_UPDATE, CITY_RESET } from '../actions/actionTypes';

const initialState = {
  city: {
    id: null,
    nome: ''
  }
};

export const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case CITY_UPDATE:
      return {
        ...state,
        city: action.city
      };
    case CITY_RESET:
      return initialState;
    default:
      return state;
  }
};
