import React, { Component } from "react";
import { connect } from "react-redux";

import { formatCNPJ } from "../helpers";

import '../assets/styles/card-detail.scss';

class CardDetail extends Component {
  state = {
    reduced: true
  }

  // Update state methods
  updateReduced = () => {
    this.setState(prevState => ({
      reduced: !prevState.reduced
    }));
  }

  render() {
    const cardClassNames = "cardDetail" + (this.state.reduced ? " -reduced" : "");
    const { estampador } = this.props;

    return (
      <div
        className={ cardClassNames }
        data-id={ estampador.id }
        onClick={ this.updateReduced }>
        <div className="container">
          { estampador.logo
            ? <img src={ estampador.logo } alt={ estampador.nome } className="logo" />
            : <div className="logo -disabled">Logo</div>
          }
          <div className="wrapper">
            <div className="name">{ estampador.nome }</div>
            <div className="link">{ this.state.reduced ? 'Ver detalhes' : 'Fechar detalhes' }</div>
          </div>
        </div>
        { this.state.reduced === false ?
          <React.Fragment>
            <div className="field cnpj">CNPJ { formatCNPJ(estampador.cnpj) }</div>
            { estampador.endereco ?
              <div className="field address">{ estampador.endereco }</div>
            : ''}
          </React.Fragment>
        : ''}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador
});

export default connect(mapStateToProps)(CardDetail);
