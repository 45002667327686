import React, { Component } from "react";
import { connect } from "react-redux";

import { updateUF } from "../actions/uf";
import { updateUFs } from "../actions/ufs";
import { updateCity } from "../actions/city";
import { updateCities } from "../actions/cities";
import { updateEstampadores } from "../actions/estampadores";
import { updateEstampador } from "../actions/estampador";
import { updateTemplate } from "../actions/template";
import { updatePedido } from "../actions/pedido";
import { resetTemplate } from "../actions/template";
import { resetEstampador } from "../actions/estampador";
import { resetCity } from "../actions/city";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import SearchBar from "../components/SearchBar";
import SearchResults from "../components/SearchResults";
import ConsultBar from "../components/ConsultBar";
import SelectEstado from "../components/SelectEstado";
import SelectCidade from "../components/SelectCidade";
import ButtonSearch from "../components/ButtonSearch";
import ButtonLocation from "../components/ButtonLocation";

import { redirectToStep } from "../helpers";

const UFS_URL = `localidades/ufs/?hasEstampador=true`;
const CITIES_URL = `localidades/cidades/uf/{uf}/?hasEstampador=true`;
const SEARCH_BY_UF_URL = `estampadores/uf/`;
const PEDIDO_BY_UF_URL = `pedidos/uf​/{uf}​/placa​/{placa}`;
const ESTAMPADOR_URL = `estampadores/`;

class Estado extends Component {
  // Lifecycle methods
  async componentDidMount() {
    this.resetApp();

    if (this.props.ufs.length !== 0) {
      this.loadCommonData();
    } else {
      await this.fetchUFs();
      this.loadCommonData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadCommonData();
    }
  }

  // Fetch data methods
  fetchUFs = async () => {
    this.props.updateLoader(true);

    try {
      const ufs = await api.get(UFS_URL).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateUFs(ufs.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchCities = async () => {
    let url = CITIES_URL;
    url = url.replace('{uf}', this.props.uf.id);

    this.props.updateLoader(true);

    try {
      const cities = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateCities(cities.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchEstampadoresByUF = async () => {
    this.props.updateLoader(true);

    try {
      const estampadores = await api.get(`${SEARCH_BY_UF_URL}${this.props.uf.sigla}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampadores(estampadores.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchPedidoByUF = async placa => {
    let url = PEDIDO_BY_UF_URL;
    url = url.replace(`{uf}`, this.props.uf.sigla).replace(`{placa}`, placa);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.handleValidPlate(pedido.data);
    } catch (err) {
      this.handleInvalidPlate(err);
    }

    this.props.updateLoader(false);
  }

  fetchEstampador = async url => {
    this.props.updateLoader(true);

    try {
      const estampador = await api.get(`${ESTAMPADOR_URL}${url}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampador(estampador.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  resetApp = _ => {
    if (this.props.template.corPrimaria) {
      this.props.resetTemplate();
    }

    if (this.props.estampador.id) {
      this.props.resetEstampador();
    }

    if (this.props.city.id) {
      this.props.resetCity();
    }
  }

  loadCommonData = async () => {
    await this.setUF(this.props.match.params.uf);

    this.fetchCities();
    //this.fetchEstampadoresByUF();
  }

  setUF = receivedUF => {
    // 1. Retrieve UF from URL
    let uf = this.props.ufs.filter(uf => uf.sigla.toLowerCase() === receivedUF)[0];

    // 2. Convert UF sigla to lowercase
    uf.sigla = uf.sigla.toLowerCase();

    // 3. Set new UF state
    this.props.updateUF(uf);

    // 4. Reset city
    if (this.props.city.id) {
      this.props.resetCity();
    }
  }

  handleValidPlate = async pedido => {
    this.props.updatePedido(pedido);

    await this.fetchEstampador(pedido.estampador.url);

    this.prepareTemplate(pedido.estampador);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidPlate = err => {
    if (err.response.status === 404) {
      this.props.history.push("/pedido-nao-encontrado");
    }
    if (err.response.status === 500) {
      this.props.updateErrorModal(true);
    }
  }

  prepareTemplate = estampador => {
    const template = {
      corPrimaria: estampador.corPrimaria,
      corSecundaria: estampador.corSecundaria,
      corTexto: estampador.corTexto ? estampador.corTexto : ''
    }

    this.props.updateTemplate(template);
  }

  render() {
    return (
      <Base>
        <SearchBar>
          <SelectEstado />
          <SelectCidade />
          <ButtonSearch />
          <div className="separator">
            <ButtonLocation />
          </div>
        </SearchBar>
        <SearchResults />
        <ConsultBar onFetchPedido={ e => this.fetchPedidoByUF(e) } />
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  uf: store.ufState.uf,
  ufs: store.ufsState.ufs,
  city: store.cityState.city,
  estampador: store.estampadorState.estampador,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateUF: updateUF,
  updateUFs: updateUFs,
  updateCity: updateCity,
  updateCities: updateCities,
  updateEstampadores: updateEstampadores,
  updateEstampador: updateEstampador,
  updateTemplate: updateTemplate,
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal,
  resetEstampador: resetEstampador,
  resetTemplate: resetTemplate,
  resetCity: resetCity
}

export default connect(mapStateToProps, mapDispatchToProps)(Estado);
