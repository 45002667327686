import React, { Component } from "react";

import Step from "./Step";

import '../assets/styles/step-status.scss';

class Steps extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="steps">
          <Step number={ 1 } name="Ofertas de Placa" link="/consulta-emplacamento" />
          <Step number={ 2 } name="Dados Pessoais" link="/preencher-informacoes" />
          <Step number={ 3 } name="Dados de Pagamento" link="/forma-de-pagamento" />
          <Step number={ 4 } name="Confirmação de Pagamento" link="/pagamento" />
          <Step number={ 5 } name="Agendamento da Instalação" link="/agendamento" />
        </ul>
        { this.props.children }
      </React.Fragment>
    );
  }
}

export default Steps;
