import React, { Component } from "react";
import { connect } from "react-redux";

import { updateStep } from "../actions/step";
import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Steps from "../components/Steps";

import PreencherInformacoesForm from "../forms/PreencherInformacoesForm";

import '../assets/styles/intern.scss';

const UPDATE_PEDIDO_NOTA_URL = `pedidos/{idPedido}/notafiscal​`;

class PreencherInformacoes extends Component {
  // Lifecycle methods
  async componentDidMount() {
    if (!this.props.pedido.id) {
      this.props.history.push("/");
    }

    await this.props.updateStep(2);
  }

  // Handle events methods
  submit = values => {
    let newPedido = this.prepareNewPedido(values);

    this.props.updatePedido(newPedido);

    this.updatePedidoNota(newPedido);
  }

  // Helpers methods
  updatePedidoNota = async pedido => {
    let url = UPDATE_PEDIDO_NOTA_URL;
    url = url.replace(`{idPedido}`, pedido.id);

    let data = {
      solicitante: pedido.solicitante
    }

    if (pedido.tipoNota !== "SemNota") {
      data.proprietario = pedido.proprietario
    }

    this.props.updateLoader(true);

    try {
      await api.put(url, data).catch(err => { throw err; });

      this.props.history.push("./forma-de-pagamento");
    } catch (err) {
      console.log('Error UpdateNota: ', err);

      if (err.response.status === 400 || err.response.status === 500) {
        this.props.updateErrorModal(true);
      }
    }

    this.props.updateLoader(false);
  }

  prepareNewPedido = values => {
    let newPedido = this.props.pedido;

    // Solicitante
    newPedido.solicitante = {};
    // newPedido.solicitante.id = 0;
    // newPedido.solicitante.codigoSistemaPagamento = "";

    if (values.inputSolicitanteCPF) {
      newPedido.solicitante.tipoPessoa = "Fisica";
      newPedido.solicitante.documento = values.inputSolicitanteCPF.replace(/[^\d]+/g,"");
    } else {
      newPedido.solicitante.tipoPessoa = "Juridica";
      newPedido.solicitante.documento = values.inputSolicitanteCNPJ.replace(/[^\d]+/g,"");
      newPedido.solicitante.matricula = values.inputSolicitanteRegistration;
    }
    newPedido.solicitante.nome = values.inputSolicitanteName;
    newPedido.solicitante.email = values.inputSolicitanteEmail;
    newPedido.solicitante.telefone = values.inputSolicitantePhone;

    if (this.props.pedido.tipoNota !== "SemNota") {
      // Proprietario
      newPedido.proprietario = {};
      // newPedido.proprietario.id = 0;
      // newPedido.proprietario.codigoSistemaPagamento = "";

      if (values.inputProprietarioCPF) {
        newPedido.proprietario.tipoPessoa = "Fisica";
        newPedido.proprietario.documento = values.inputProprietarioCPF.replace(/[^\d]+/g,"");
      } else {
        newPedido.proprietario.tipoPessoa = "Juridica";
        newPedido.proprietario.documento = values.inputProprietarioCNPJ.replace(/[^\d]+/g,"");
      }
      newPedido.proprietario.nome = values.inputProprietarioName;
      newPedido.proprietario.email = values.inputProprietarioEmail;
      newPedido.proprietario.telefone = values.inputProprietarioPhone;

      // Endereco Proprietario
      newPedido.proprietario.endereco = {};
      newPedido.proprietario.endereco.id = 0;
      newPedido.proprietario.endereco.cep = values.inputProprietarioCEP;
      newPedido.proprietario.endereco.logradouro = values.inputProprietarioEndereco;
      newPedido.proprietario.endereco.numero = parseInt(values.inputProprietarioNumero);
      newPedido.proprietario.endereco.complemento = values.inputProprietarioComplemento;
      newPedido.proprietario.endereco.bairro = values.inputProprietarioBairro;
      newPedido.proprietario.endereco.ibgeCidade = parseInt(this.props.cityAddress.id);
      newPedido.proprietario.endereco.cidade = this.props.cityAddress.nome;
      newPedido.proprietario.endereco.uf = this.props.cityAddress.siglaUf;
    }

    return newPedido;
  }

  render() {
    return (
      <Base hideTitle={ true }>
        <div className="internContainer -no-margin">
          <CardDetail />
          <Steps>
            <PreencherInformacoesForm onSubmit={this.submit} />
          </Steps>
        </div>
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido,
  ufAddress: store.ufAddressState.ufAddress,
  cityAddress: store.cityAddressState.cityAddress
});

const mapDispatchToProps = {
  updateStep: updateStep,
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(PreencherInformacoes);
