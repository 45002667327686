import React, { Component } from "react";
import { connect } from "react-redux";

import ArrowIcon from "../embedded/ArrowIcon";

import '../assets/styles/submit-button.scss';

class SubmitButton extends Component {

  render() {
    const { template } = this.props;
    const classNames = "submitButton" + (this.props.disabled ? " -disabled" : "");
    const borderStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div
        className={ classNames }
        onClick={ this.props.onClick }>
        { this.props.text }
        <div className="border" style={ borderStyle }></div>
        <ArrowIcon disabled={ this.props.disabled } />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(SubmitButton);
