import { UF_UPDATE, UF_RESET } from './actionTypes';

export const updateUF = value => ({
  type: UF_UPDATE,
  uf: value
});

export const resetUF = _ => ({
  type: UF_RESET
});
