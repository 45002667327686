import React, { Component } from "react";
import { withRouter } from 'react-router'
import { connect } from "react-redux";

import { updateEstampador } from "../actions/estampador";
import { updatePedido } from "../actions/pedido";
import { updateTemplate } from "../actions/template";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import { redirectToStep } from "../helpers";

const RESET_AGENDAMENTO_URL = `agendamentos/resetar`;

class ButtonEditAgendamento extends Component {

  resetAgendamento = async () => {
    this.props.updateLoader(true);

    try {
      let pedido = this.props.pedido;
      const pedidoEdit = await api.post(RESET_AGENDAMENTO_URL, pedido).catch(err => { throw err; });
      this.handleResetAgendamento(pedidoEdit.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  handleResetAgendamento = async pedido => {
    this.props.updatePedido(pedido);

    this.prepareTemplate(pedido.estampador);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  prepareTemplate = estampador => {
    const template = {
      corPrimaria: estampador.corPrimaria,
      corSecundaria: estampador.corSecundaria,
      corTexto: estampador.corTexto ? estampador.corTexto : ''
    }

    this.props.updateTemplate(template);
  }

  onClickEditAgendamento = () => {
    this.resetAgendamento();
  }

  render() {
    const { template } = this.props;

    const backgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria, color: template.corTexto, cursor: "pointer" } : {cursor: "pointer"};
    return (
      <button 
        title="Reagendar"
        className="buttonEdit"
        style={backgroundStyle}
        onClick={e => this.onClickEditAgendamento(e)}>
          Reagendar
      </button>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido,
  template: store.templateState.template,
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal,
  updatePedido: updatePedido,
  updateTemplate: updateTemplate,
  updateEstampador: updateEstampador
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ButtonEditAgendamento));