import { TOUR_MODAL_UPDATE } from '../actions/actionTypes';

const initialState = {
  tourModal: false
};

export const tourModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOUR_MODAL_UPDATE:
      return {
        ...state,
        tourModal: action.tourModal
      };
    default:
      return state;
  }
};
