import { ESTAMPADORES_UPDATE, ESTAMPADORES_RESET } from './actionTypes';

export const updateEstampadores = value => ({
  type: ESTAMPADORES_UPDATE,
  estampadores: value
});

export const resetEstampadores = _ => ({
  type: ESTAMPADORES_RESET
});
