import React, { Component } from "react";
import { connect } from "react-redux";

import { updateCity } from "../actions/city";

import Options from "../components/Options";

import '../assets/styles/select.scss';

class SelectCidade extends Component {
  state = {
    focused: false,
    cities: [],
    search: ''
  }

  // Event handler methods
  handleSelectFocus = _ => {
    this.setState(state => ({
      focused: !state.focused,
      cities: this.props.cities,
      search: ''
    }));
  }

  handleInputChange = e => {
    const cities = this.props.cities.filter(city => city.nome.toLowerCase().includes(e.target.value.toLowerCase()));

    this.setState({
      cities: cities,
      search: e.target.value
    });
  }

  onChangeSelectCity = e => {
    const city = {
      nome: e.currentTarget.dataset.value,
      id: e.currentTarget.dataset.id
    }

    this.props.updateCity(city);
  }

  render() {
    const { city, cities, template } = this.props;
    const classNames = "select" + (this.state.focused ? " -focused" : "") + (cities.length === 0 ? " -disabled" : "");
    const borderStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className={ classNames }>
        <div className="border" style={ borderStyle }></div>
        <div className="label">Cidade</div>
        <div className="field" onClick={ _ => this.handleSelectFocus() }>
          <input
            className={"selected " + (city.id === null ? "-empty" : "")}
            onChange={ e => this.handleInputChange(e) }
            placeholder={ city.id === null ? 'Selecione' : city.nome }
            value={ this.state.search }>
          </input>
          <Options>
            { this.state.cities.map((city, key) => (
              <div
                data-value={ city.nome }
                data-id={ city.id }
                className="option"
                onClick={ e => this.onChangeSelectCity(e) }
                key={ key }>
                { city.nome }
              </div>
            ))}
          </Options>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  city: store.cityState.city,
  cities: store.citiesState.cities,
  template: store.templateState.template
});

const mapDispatchToProps = {
  updateCity: updateCity
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCidade);
