import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";

import { updateUFAddress } from "../actions/ufAddress";
import { updateUFsAddress } from "../actions/ufsAddress";
import { updateCityAddress } from "../actions/cityAddress";
import { updateCitiesAddress } from "../actions/citiesAddress";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import SelectEstadoAddress from "../components/SelectEstadoAddress";
import SelectCidadeAddress from "../components/SelectCidadeAddress";

import { cepMask } from "../helpers";

import '../assets/styles/intern.scss';
import '../assets/styles/text-field.scss';

const UFS_URL = `localidades/ufs`;
const CITIES_URL = `localidades/cidades/uf/{uf}`;
const CEP_URL = `localidades/endereco/cep/{cep}`;

class EnderecoProprietario extends Component {
  // Lifecycle methods
  async componentDidMount() {
    const { pedido } = this.props;
    
    this.resetAddress();

    await this.fetchUFs();

    if (pedido.proprietario !== undefined && pedido.proprietario.endereco.cep !== null && pedido.proprietario.endereco.cep.length === 8)  {
      this.props.change('inputProprietarioCEP', pedido.proprietario.endereco.cep);
      this.fetchAddress(pedido.proprietario.endereco.cep);
    }

    if (pedido.proprietario !== undefined && pedido.proprietario.endereco.numero !== '0') {
      this.props.change('inputProprietarioNumero', pedido.proprietario.endereco.numero);
    }
      
    if ( pedido.proprietario !== undefined && pedido.proprietario.endereco !== undefined && pedido.proprietario.endereco.complemento !== '') {
      this.props.change('inputProprietarioComplemento', pedido.proprietario.endereco.complemento);
    }
  }

  // Update state methods
  updateUF = e => {
    const uf = {
      id: e.target.dataset.id,
      nome: e.target.dataset.nome,
      sigla: e.target.dataset.sigla
    };

    const city = {
      id: null,
      nome: ""
    };

    this.props.updateUFAddress(uf);
    this.props.updateCityAddress(city);

    this.fetchCities(e.target.dataset.id);
  }

  updateCity = e => {
    const city = {
      id: e.target.dataset.id,
      nome: e.target.dataset.nome
    }

    this.props.updateCityAddress(city);
  }

  // Fetch data methods
  fetchUFs = async () => {
    this.props.updateLoader(true);

    try {
      const ufs = await api.get(UFS_URL).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateUFsAddress(ufs.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchCities = async ufId => {
    let url = CITIES_URL;
    url = url.replace('{uf}', ufId);

    this.props.updateLoader(true);

    try {
      const cities = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateCitiesAddress(cities.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchAddress = async cep => {
    let url = CEP_URL;
    url = url.replace('{cep}', cep);

    this.props.updateLoader(true);

    try {
      const address = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.updateAddress(address.data);
    } catch (err) {
      // this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  resetAddress = _ => {
    if (this.props.ufAddress.id) {
      const uf = {
        id: null,
        nome: "",
        sigla: ""
      };

      this.props.updateUFAddress(uf);
    }

    if (this.props.cityAddress.id) {
      const city = {
        id: null,
        nome: ""
      };

      this.props.updateCityAddress(city);
    }
  }

  handleChangeCEP = e => {
    const cep = e.target.value.replace(".", "").replace("-", "");

    if (cep.length === 8) {
      this.fetchAddress(cep);
    }
  }

  updateAddress = async address => {
    this.props.change('inputProprietarioBairro', address.bairro);
    this.props.change('inputProprietarioEndereco', address.logradouro);

    const uf = this.props.ufsAddress.filter(uf => uf.id === address.ufId);
    this.props.updateUFAddress(uf[0]);

    await this.fetchCities(address.ufId);

    const city = this.props.citiesAddress.filter(city => city.id === address.ibgeCidade);
    this.props.updateCityAddress(city[0]);
  }

  render() {
    const { template } = this.props;
    const detailStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <div className="fields">
        <h3 className="title">
          Endereço do(a) Proprietário(a)
          <div className="detail" style={ detailStyle }></div>
        </h3>
        <p className="text">
          Todos os itens com * são obrigatórios
        </p>
        <div className="row">
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <div className="label">CEP *</div>
            <Field
              name="inputProprietarioCEP"
              component="input"
              type="text"
              placeholder="00.000-000"
              minLength="10"
              maxLength="10"
              className="field"
              required
              {...cepMask}
              onChange={ e => this.handleChangeCEP(e) }
            />
          </div>
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Endereço *</div>
            <Field name="inputProprietarioEndereco" component="input" type="text" placeholder="Digite aqui" className="field" required />
          </div>
          <div className="textField -reduced">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Número *</div>
            <Field name="inputProprietarioNumero" component="input" type="number" placeholder="0000" className="field" required />
          </div>
          <div className="textField -reduced">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Complemento</div>
            <Field name="inputProprietarioComplemento" component="input" type="text" placeholder="Digite aqui" className="field" />
          </div>
          <div className="textField">
            <div className="border" style={ detailStyle }></div>
            <div className="label">Bairro *</div>
            <Field name="inputProprietarioBairro" component="input" type="text" placeholder="Digite aqui" className="field" required />
          </div>
        </div>
        <div className="row">
          <SelectEstadoAddress onClick={ e => this.updateUF(e) } />
          <SelectCidadeAddress onClick={ e => this.updateCity(e) } />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  ufAddress: store.ufAddressState.ufAddress,
  ufsAddress: store.ufsAddressState.ufsAddress,
  cityAddress: store.cityAddressState.cityAddress,
  citiesAddress: store.citiesAddressState.citiesAddress,
  template: store.templateState.template,
  pedido: store.pedidoState.pedido
});

const mapDispatchToProps = {
  updateUFAddress: updateUFAddress,
  updateUFsAddress: updateUFsAddress,
  updateCityAddress: updateCityAddress,
  updateCitiesAddress: updateCitiesAddress,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(EnderecoProprietario);
