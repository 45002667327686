import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import ConsultaEmplacamento from "./pages/ConsultaEmplacamento";
import PreencherInformacoes from "./pages/PreencherInformacoes";
import FormaDePagamento from "./pages/FormaDePagamento";
import Pagamento from "./pages/Pagamento";
import Agendamento from "./pages/Agendamento";
import Resumo from "./pages/Resumo";
import PedidoNaoEncontrado from "./pages/PedidoNaoEncontrado";
import PedidoCancelado from "./pages/PedidoCancelado";
import AutorizacaoInvalida from "./pages/AutorizacaoInvalida";
import PaginaNaoEncontrada from "./pages/PaginaNaoEncontrada";
import Estado from "./pages/Estado";
import Estampador from "./pages/Estampador";
import Grupo from "./pages/Grupo";

import { removeAccessToken, removeRefreshToken } from "./services/token";

class App extends Component {
  // Lifecycle methods
  async componentDidMount() {
    this.resetTokens();
  }

  // Helpers methods
  resetTokens = _ => {
    removeAccessToken();
    removeRefreshToken();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ Home } />
          <Route exact path="/pedido-nao-encontrado" component={ PedidoNaoEncontrado } />
          <Route exact path="/pagina-nao-encontrada" component={ PaginaNaoEncontrada } />
          <Route exact path="/:uf" component={ Estado } />
          <Route exact path="/:uf/:estampador" component={ Estampador } />
          <Route exact path="/:uf/:estampador/consulta-emplacamento" component={ ConsultaEmplacamento } />
          <Route exact path="/:uf/:estampador/preencher-informacoes" component={ PreencherInformacoes } />
          <Route exact path="/:uf/:estampador/forma-de-pagamento" component={ FormaDePagamento } />
          <Route exact path="/:uf/:estampador/pagamento" component={ Pagamento } />
          <Route exact path="/:uf/:estampador/agendamento" component={ Agendamento } />
          <Route exact path="/:uf/:estampador/resumo" component={ Resumo } />
          <Route exact path="/:uf/:estampador/pedido-cancelado" component={ PedidoCancelado } />
          <Route exact path="/:uf/:estampador/autorizacao-invalida" component={ AutorizacaoInvalida } />
          <Route exact path="/:uf/grupo/:grupo" component={ Grupo } />
          <Redirect to="/pagina-nao-encontrada" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
