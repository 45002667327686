import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';

import { ufReducer } from './uf';
import { ufsReducer } from './ufs';
import { cityReducer } from './city';
import { citiesReducer } from './cities';
import { groupReducer } from './group';
import { estampadorReducer } from './estampador';
import { estampadoresReducer } from './estampadores';
import { templateReducer } from './template';
import { pedidoReducer } from './pedido';
import { stepReducer } from './step';
import { ufAddressReducer } from './ufAddress';
import { ufsAddressReducer } from './ufsAddress';
import { cityAddressReducer } from './cityAddress';
import { citiesAddressReducer } from './citiesAddress';
import { agendamentoReducer } from './agendamento';
import { loaderReducer } from './loader';
import { errorModalReducer } from './errorModal';
import { tourModalReducer } from './tourModal';

export const Reducers = combineReducers({
  ufState: ufReducer,
  ufsState: ufsReducer,
  cityState: cityReducer,
  citiesState: citiesReducer,
  groupState: groupReducer,
  estampadorState: estampadorReducer,
  estampadoresState: estampadoresReducer,
  templateState: templateReducer,
  pedidoState: pedidoReducer,
  stepState: stepReducer,
  ufAddressState: ufAddressReducer,
  ufsAddressState: ufsAddressReducer,
  cityAddressState: cityAddressReducer,
  citiesAddressState: citiesAddressReducer,
  agendamentoState: agendamentoReducer,
  loaderState: loaderReducer,
  errorModalState: errorModalReducer,
  tourModalState: tourModalReducer,
  form: formReducer
});
