import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";

import CheckIcon from "../embedded/CheckIcon";

import '../assets/styles/step.scss';

class Step extends Component {
  render() {
    const { step, number, name, template, link } = this.props;
    const numberBeforeStyle = template.corPrimaria ? { backgroundColor: template.corSecundaria } : {};
    const stepBeforeStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    let numberAfterStyle = {};

    if (template.corPrimaria) {
      if (step > number) {
        numberAfterStyle = { borderColor: template.corPrimaria, backgroundColor: template.corPrimaria };
      } else {
        numberAfterStyle = { borderColor: template.corPrimaria };
      }
    }

    let classNames = `step step-${number}`;

    if (step > number) {
      classNames += ' -checked';
    } else if (step < number) {
      classNames += ' -disabled';
    }

    return (
      <li className={ classNames }>
        {/* <Link to={ link } className="link"> */}
          <div className="before" style={ stepBeforeStyle }></div>
          <div className="number">
            <div className="before" style={ numberBeforeStyle }></div>
            <span className="layer">{ number }</span>
            <div className="after" style={ numberAfterStyle }>
              <div className="icon">
                <CheckIcon />
              </div>
            </div>
          </div>
          <div className="name">{ name }</div>
          <div className="after"></div>
        {/* </Link> */}
      </li>
    );
  }
}

const mapStateToProps = store => ({
  step: store.stepState.step,
  template: store.templateState.template
});

export default connect(mapStateToProps)(Step);
