import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleBarReact from "simplebar-react";

import { getDayOfTheWeek } from "../helpers";

import 'simplebar/src/simplebar.css';

class PeriodItem extends Component {

  // Helpers methods
  addZero = i => {
    if (i < 10) {
      i = "0" + i;
    }

    return i;
  }

  render() {
    const { date, selected, selectedTimeKey, template } = this.props;
    const periodClassNames = "period" + (selected ? " -selected" : "");
    const rawDate = new Date(date.data);
    const fullDate = new Date(rawDate.setHours(rawDate.getHours()+3));

    let periodBorderStyle = template.corPrimaria ? { borderColor: template.corPrimaria } : {};
    let timeBackgroundStyle = template.corPrimaria ? { backgroundColor: template.corPrimaria } : {};

    return (
      <li className={ periodClassNames } data-date={ date.data } style={ periodBorderStyle }>
        <div className="date">
          <div>{ this.addZero(fullDate.getDate()) }/{ fullDate.getMonth() + 1 }</div>
          <div className="name">{ getDayOfTheWeek(fullDate.getDay()) }</div>
        </div>
        <SimpleBarReact style={{ maxHeight: 89 }}>
          { date.horarios.map((horario, key) =>
            <div
              key={ key }
              data-key={ key }
              className={ "time" + (horario.disponivel ? "" : " -disabled") + (selected && selectedTimeKey === key.toString() ? " -selected" : "") }
              style={ selected && selectedTimeKey === key.toString() ? { backgroundColor: template.corPrimaria, color: template.corTexto } : {} }
              onClick={ e => this.props.onClick(e) }>
              <div className="background" style={ timeBackgroundStyle }></div>
              <div className="value">{ horario.horario }</div>
            </div>
          )}
        </SimpleBarReact>
      </li>
    )
  };
}

const mapStateToProps = store => ({
  template: store.templateState.template
});

export default connect(mapStateToProps)(PeriodItem);
