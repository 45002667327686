import React, { Component } from "react";
import { connect } from "react-redux";

import Plate from "./Plate";

import { formatRealValue } from "../helpers";

import '../assets/styles/offer.scss';

class Offer extends Component {
  render() {
    const { pedido, placa } = this.props;

    const descricao = placa.descricao.toLowerCase();
    let type = "carro";

    if (descricao.includes(' moto ')) {
      type = "moto";
    } else if (descricao.includes(' par ')) {
      type = "par";
    }

    return (
      <div className="offer">
        <Plate type={ type } placa={ pedido.placa } />
        <div className="information">
        <div className="description">{ descricao }</div>
          <div className="value">R$ { formatRealValue(placa.valor) }</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  pedido: store.pedidoState.pedido
});

export default connect(mapStateToProps)(Offer);
